import { FC } from 'react'

import { BaseText } from '@genie-fintech/ui/components/fields'
import { propsWithClassNames, toClassNames } from '@genie-fintech/ui/functions'

import useOTPInput from '$actions/useOTPInput'

import { container, field, input } from './styles.css'

export type OTPInputProps = ReturnType<typeof useOTPInput>

export const OTPInput: FC<OTPInputProps> = ({
  className,
  passwordFieldProps,
  containerRef
}) => (
  <article ref={containerRef} className={toClassNames(container, className)}>
    {passwordFieldProps.map(
      ({ containerProps, inputProps, ...restProps }, index) => (
        <BaseText
          key={index}
          containerProps={propsWithClassNames(containerProps, field)}
          inputProps={propsWithClassNames(inputProps, input)}
          {...restProps}
        />
      )
    )}
  </article>
)

export default OTPInput
