import { useRequest } from 'ahooks'
import { toast } from 'react-hot-toast'

import { MobileOTPResendRequest } from '$api/types'
import { AnonymousAuthAPI } from '$api/anonymous-auth'

const { mobileOTPResend, errorMessageResolver, successResolver } =
  new AnonymousAuthAPI(null)

export const useMobileOTPResend = () => {
  const { loading, runAsync } = useRequest(mobileOTPResend, { manual: true })

  const resend = (data: MobileOTPResendRequest) => {
    return toast
      .promise(runAsync(data), {
        loading: 'Resending..',
        error: e => errorMessageResolver(e, 'Invalid credential'),
        success: 'OTP requested'
      })
      .then(successResolver)
  }

  return { resend, loading }
}

export default useMobileOTPResend
