import { useState } from 'react'

import useSignal from '$actions/useSignal'

import { profile } from '$store/profile'

import useEmailVerifyRequest from './useEmailVerifyRequest'
import usePhoneVerification from './usePhoneVerificaion'
import { getPhone } from '$app/utilities'

type ActionTypeEmail = {
  id: 'email'
  props?: never
}

type ActionTypePhone = {
  id: 'phone'
  props: Pick<
    ReturnType<typeof usePhoneVerification>,
    'modal' | 'otpBlock' | 'cancel'
  >
}

type ActionTypes = ActionTypeEmail | ActionTypePhone

type Action = {
  title: string
  description: string
  loading: boolean
  onClick: VoidFunction
} & ActionTypes

export const useSidebarActions = () => {
  const profileStore = useSignal(profile)
  const { is_email_verified, is_phone_verified, email, phone_code, phone_no } =
    { ...profileStore?.data }

  const [activeIndex, setActiveIndex] = useState(0)

  const emailVerify = useEmailVerifyRequest()
  const phoneVerify = usePhoneVerification()

  const hasPhone = !!getPhone({ phone_code, phone_no })

  const actions = (() => {
    const list: Action[] = []

    if (email && !is_email_verified) {
      const { loading, request: onClick } = emailVerify

      list.push({
        id: 'email',
        title: 'Verify Your Email',
        description:
          'By clicking the verify button to proceed the email verification process.',
        loading,
        onClick
      })
    }

    if (hasPhone && !is_phone_verified) {
      const { modal, otpBlock, loading, request: onClick, cancel } = phoneVerify

      list.push({
        id: 'phone',
        title: 'Verify Your Phone',
        description:
          'By clicking the verify button to proceed the phone verification process.',
        loading,
        onClick,
        props: { modal, otpBlock, cancel }
      })
    }

    return list
  })()

  const formattedActions = actions.map((action, index) => ({
    ...action,
    isActive: index === activeIndex
  }))

  const hasActions = !!actions.length
  const hasMultipleActions = actions.length > 1

  const rotateAction = () => {
    setActiveIndex(prevIndex => (prevIndex + 1) % actions.length)
  }

  return {
    formattedActions,
    hasActions,
    hasMultipleActions,
    rotateAction,

    emailVerify,
    phoneVerify
  }
}

export default useSidebarActions
