import { createContext, useContext } from 'react'

import { useModal } from './useModal'

export * from './useModal'
export * from './useConfirmModal'

const internalContext = createContext<ReturnType<typeof useModal>>(undefined!)

export const useModalContext = () => useContext(internalContext)

export type UseModalContextReturns = ReturnType<typeof useModalContext>

export const Provider = internalContext.Provider
