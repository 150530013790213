import { TCountryCode } from 'countries-list'
import { LiteralUnion } from 'type-fest'

import { TokenDetail } from '$store/session/tokens'

export type AuthMethods = (typeof authMethods)[number]
export const authMethods = ['email', 'phone'] as const

export type AuthFactorTypes = (typeof authFactorTypes)[number]
export const authFactorTypes = ['email_otp', 'sms_otp', 'totp'] as const

export type AuthQueryParams = {
  auth_user?: TokenDetail['id']
  auth_method?: LiteralUnion<AuthMethods, string>
  auth_country?: LiteralUnion<TCountryCode, string>
  auth_phone_number?: LiteralUnion<number, string>
}
