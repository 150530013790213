import { useRequest } from 'ahooks'
import toast from 'react-hot-toast'

import { DEFAULT_OTP_CODE_LENGTH, DEFAULT_OTP_RETRY_TIMER } from '$constants'

import useSignal from '$actions/useSignal'
import useOTPInput from '$actions/useOTPInput'
import useOTPBlock from '$actions/useOTPBlock'
import useTimer from '$actions/useTimer'

import { useModal } from '$contexts/Modal'

import { refreshState } from '$store/profile'
import api from '$model/api'
import { errorMessageResolver } from '$services/api/common'

export const usePhoneVerification = () => {
  const otpTimer = useTimer(DEFAULT_OTP_RETRY_TIMER)

  const {
    auth: { phoneVerificationRequest, phoneVerificationConfirm }
  } = useSignal(api)

  const modal = useModal({
    disabledOverlayClick: true,
    onClose: () => {
      reset()
    }
  })

  const initReq = useRequest(phoneVerificationRequest, {
    manual: true,
    onFinally: () => {
      otpInput.resetPasswords()
      otpTimer.reset()
    }
  })

  const confirmReq = useRequest(phoneVerificationConfirm, {
    manual: true,
    onFinally: () => {
      refreshState()
    }
  })

  const otpInput = useOTPInput({
    length: DEFAULT_OTP_CODE_LENGTH,
    disabled: confirmReq.loading,
    onFilled: code => {
      const { runAsync, cancel } = confirmReq

      cancel()

      toast.promise(runAsync({ code }), {
        error: errorMessageResolver,
        loading: 'Verifying..',
        success: () => 'Phone number verified successfully!'
      })
    }
  })

  const otpBlock = useOTPBlock({
    otpInput,
    title: 'Enter Code',
    description: `We’ve sent ${DEFAULT_OTP_CODE_LENGTH} digit verification code to your phone number.`,
    timerActive: otpTimer.active,
    timerSeconds: otpTimer.seconds,
    onResend: () => {
      const { refreshAsync, cancel } = initReq

      cancel()

      toast.promise(refreshAsync(), {
        error: errorMessageResolver,
        loading: 'Resending..',
        success: 'OTP requested'
      })
    }
  })

  const loading = initReq.loading

  const reset = () => {
    initReq.cancel()
    initReq.mutate()
  }

  const request = () => {
    modal.modalControls.open()

    toast.promise(initReq.runAsync(), {
      error: errorMessageResolver,
      loading: 'Requesting phone verification..',
      success: 'Requested!'
    })
  }

  const cancel = modal.modalControls.close

  return { loading, request, modal, otpBlock, cancel }
}

export default usePhoneVerification
