import { FC, useMemo } from 'react'

import { trueOrUndefined } from '@genie-fintech/ui/functions'

import { buildURL, pluralize, valueOrUndefined } from '$app/utilities'

import useAppList from '$actions/useAppList'
import useSignal from '$actions/useSignal'
import usePageClass from '$browser/usePageClass'

import PageHead from '$layouts/AuthenticatedLayout/PageHead'
import PageRegion from '$layouts/AuthenticatedLayout/PageRegion'
import PageContents from '$layouts/AuthenticatedLayout/PageContents'
import AppCard from '$elements/AppCard'
import CenterLoader from '$elements/CenterLoader'

import { profile } from '$store/profile'

import { list } from './styles.css'

const pageName = 'Apps'

export const Apps: FC = () => {
  usePageClass({ name: pageName })

  const { formatted, isEmpty, isLoading } = useAppList()

  const profileStore = useSignal(profile)
  const { data } = { ...profileStore }

  const { id: auth_user } = { ...data }

  const withAuthUserQueryParam = useMemo(
    () =>
      formatted.map(({ domain, environment, ...rest }) => ({
        ...rest,
        domain,
        environment: environment?.value,
        href: buildURL(domain, { queryParams: { auth_user } }).toString()
      })),
    [formatted, auth_user]
  )

  const appListCount = formatted.length

  const title = useMemo(() => {
    if (!isEmpty) {
      return `Access ${appListCount.toLocaleString()} ${pluralize(
        appListCount,
        {
          plural: 'Applications',
          singular: 'Application'
        }
      )} To Use`
    }

    return 'Applications'
  }, [appListCount, isEmpty])

  const description = useMemo(() => {
    if (isLoading) return 'Loading your apps...'

    if (isEmpty) return "You don't have access to any applications."

    return 'This user can access these applications by related assigned roles and groups.'
  }, [isEmpty, isLoading])

  const applistTitle = useMemo(() => {
    if (isLoading) return

    if (isEmpty) return 'No apps found.'

    return 'Applications Links'
  }, [isEmpty, isLoading])

  const applistDescription = useMemo(() => {
    if (isLoading || isEmpty) return

    return 'You can access following applications.'
  }, [isEmpty, isLoading])

  return (
    <>
      <PageHead title={title} description={description} />

      <PageRegion>
        <PageContents
          title={applistTitle}
          description={applistDescription}
          containerProps={{
            'data-testid': valueOrUndefined(isEmpty, 'no-apps')
          }}
          mainProps={{ 'data-loading': trueOrUndefined(isLoading) }}
          mainClassName={list}
        >
          {isLoading ? (
            <CenterLoader />
          ) : (
            withAuthUserQueryParam.map(
              ({
                id,
                name,
                domain,
                environment,
                href,
                description,
                logo,
                brand
              }) => (
                <AppCard
                  key={id}
                  href={href}
                  name={name}
                  description={description}
                  logo={logo}
                  brand={brand}
                  environment={environment}
                  aProps={{
                    'data-testid': `app-name:${name} app-id:${id} app-domain:${domain}`
                  }}
                />
              )
            )
          )}
        </PageContents>
      </PageRegion>
    </>
  )
}

export default Apps
