import { useRequest } from 'ahooks'
import { Optional } from 'utility-types'
import { toast } from 'react-hot-toast'

import { DEFAULT_OTP_RETRY_TIMER } from '$constants'

import { asyncSuccessResolver } from '$app/utilities'
import { useModal } from '$contexts/Modal'

import useTimer from '$actions/useTimer'

import { MfaAuthAPI } from '$services/api/mfa-auth'
import {
  errorMessageResolver,
  errorStatusResolver,
  successResolver
} from '$api/common'
import { ResponseResolver } from '$api/types'
import {
  MFAChallengeRequest,
  MfaTokenType,
  With2FAGuardErrorResponse
} from '$api/types'

import { Token } from '$store/session/tokens'

import { ChoiceProps } from '$blocks/MFABlock/Choice'

import useOTPBlock from './useOTPBlock'
import useOTPInput from './useOTPInput'

type DataProps = Pick<
  ResponseResolver<With2FAGuardErrorResponse>,
  'available_factors'
>

export type UseMFABlockOptions = {
  apiInstance: MfaAuthAPI
  preferred_mfa_token_type?: MfaTokenType
  data: DataProps
  onVerified: (mfa_token: Token) => void
  onExited?: VoidFunction
  onCancelled?: (action: Promise<void>) => void
}

export const useMFABlock = ({
  preferred_mfa_token_type = 'ott',
  data,
  onVerified,
  onCancelled,
  onExited,
  apiInstance: { mfaChallenge, mfaRetry, mfaVerify }
}: UseMFABlockOptions) => {
  const modalProps = useModal({
    disabledOverlayClick: true,
    onOverlayClick: () => onExit()
  })
  const { modalControls, modalControlsAsync } = modalProps

  const otpTimer = useTimer(DEFAULT_OTP_RETRY_TIMER)

  const challenge = asyncSuccessResolver(mfaChallenge, successResolver)
  const retry = asyncSuccessResolver(mfaRetry, successResolver)
  const verify = asyncSuccessResolver(mfaVerify, successResolver)

  const challengeReq = useRequest(challenge, { manual: true })
  const resendReq = useRequest(retry, { manual: true })
  const verifyReq = useRequest(verify, { manual: true })

  const requests = [challengeReq, resendReq, verifyReq]

  const challengeResult = challengeReq.data?.data
  const verifyResult = verifyReq.data?.data

  const challenge_id = challengeResult?.challenge_id
  const factor_type = challengeResult?.factor_type

  // Could be undfined based on factor_type
  const hint = challengeResult?.hint
  const code_length = challengeResult?.code_length
  const reference_code = challengeResult?.reference_code

  const mfa_token = verifyResult?.mfa_token

  const isAnyLoading = requests.map(({ loading }) => loading).includes(true)
  const disabled = isAnyLoading

  const state = (() => {
    if (code_length) return 'otp'

    return 'choice'
  })()

  const otpInput = useOTPInput({
    length: code_length,
    disabled,
    onFilled: async (code, resetInput) => {
      if (!challenge_id) return

      const { runAsync, cancel } = verifyReq

      cancel()

      const {
        data: { mfa_token }
      } = await toast
        .promise(runAsync({ challenge_id, code }), {
          error: onApiError,
          loading: 'Verifying..',
          success: 'Verified.'
        })
        .finally(resetInput)

      onVerified(mfa_token)
    }
  })

  const otpBlock = useOTPBlock({
    otpInput,
    title: 'OTP Verification',
    description: hint || 'We have sent a code to you.',
    timerActive: otpTimer.active,
    timerSeconds: otpTimer.seconds,
    refCode: reference_code,
    onResend: () => {
      if (!challenge_id) return

      const { runAsync, cancel } = resendReq

      cancel()

      toast
        .promise(runAsync({ challenge_id }), {
          error: onApiError,
          loading: 'Resending..',
          success: 'Sent'
        })
        .finally(() => {
          otpInput.resetPasswords()
          otpTimer.reset()
        })
    }
  })

  const reset = () => {
    requests.forEach(({ cancel, mutate }) => {
      cancel()
      mutate()
    })
    otpInput.resetPasswords()
  }

  const resetChallenge = () => {
    challengeReq.cancel()
    challengeReq.mutate()
    otpInput.resetPasswords()
  }

  const exit = async (resetStates = true) => {
    await modalControlsAsync.close()

    if (resetStates) {
      reset()
    }

    onExited?.()
  }

  const takeChallenge = ({
    type = preferred_mfa_token_type,
    ...rest
  }: Optional<MFAChallengeRequest, 'type'>) => {
    const { runAsync } = challengeReq

    resetChallenge()
    otpTimer.reset()

    const successMessage = () => {
      if (factor_type == 'email_otp') return 'OTP requested.'

      return 'Requested.'
    }

    const loadingMessage = () => {
      if (factor_type == 'email_otp') return 'Requesting OTP.'

      return 'Sending..'
    }

    toast.promise(runAsync({ type, ...rest }), {
      error: onApiError,
      loading: loadingMessage(),
      success: successMessage()
    })
  }

  const retakeSameChallenge = () => {
    const { params } = challengeReq

    // Make sure previous data exists
    if (!params.length) return

    takeChallenge(...params)
  }

  const onApiError = (err: unknown) => {
    if (errorStatusResolver(err) === 401) {
      reset()
    }

    return errorMessageResolver(err)
  }

  const onSelect: ChoiceProps['onClick'] = ({ type: factor_type }) => {
    takeChallenge({ factor_type })
  }

  const onExit = () => {
    const action = exit()

    onCancelled?.(action)
  }

  return {
    otpBlock,
    mfa_token,
    data,
    modalProps,
    modalControls,
    modalControlsAsync,
    disabled,
    state,
    reset,
    exit,
    onExit,
    onSelect,
    resetChallenge,
    retakeSameChallenge
  }
}

export default useMFABlock
