import { FC, PropsWithChildren, useEffect } from 'react'
import { useRequest } from 'ahooks'
import { toast } from 'react-hot-toast'

import { TagsNoRef } from '@genie-fintech/ui/types'

import { useModal } from '$contexts/Modal'

import useRouter from '$actions/useRouter'
import useMFABlock from '$actions/useMFABlock'
import useSignal from '$actions/useSignal'
import use2FAHelper from '$actions/use2FAHelper'

import MFABlock from '$blocks/MFABlock'

import ModalWithHeader from '$elements/ModalWithHeader'

import UpdatePasswordForm from '$hook-forms/UpdatePasswordForm'
import useUpdatePasswordForm from '$hook-forms/UpdatePasswordForm/useUpdatePasswordForm'

import api from '$model/api'
import { ToastMessage } from '$app/types'

const { errorMessageResolver } = api.value.auth

type ChangePasswordButtonProps = Omit<TagsNoRef<'button'>, 'onClick'>

const toastMsg: ToastMessage = {
  loading: 'Updating..',
  error: errorMessageResolver,
  success: 'Password update successful.'
}

export const ChangePasswordButton: FC<
  PropsWithChildren<ChangePasswordButtonProps>
> = props => {
  const {
    queryParams: { open_change_password },
    updateQueryParams
  } = useRouter()

  const {
    auth: { updatePassword },
    mfaAuth: apiInstance
  } = useSignal(api)

  const passwordModal = useModal({
    onClose: () => {
      mfaBlock.exit()

      formProps.reset()
    }
  })
  const { close: closePasswordModal } = passwordModal.modalControls

  const { loading, runAsync, error, params } = useRequest(updatePassword, {
    manual: true,
    onError: err => {
      if (twoFAHelper.is2FAError(err)) {
        mfaBlock.modalControls.open()
      }
    }
  })

  const twoFAHelper = use2FAHelper({ getMfaBlock: () => mfaBlock })
  const available_factors = twoFAHelper.getFactorsByError(error)

  const mfaBlock = useMFABlock({
    apiInstance,
    data: { available_factors },
    onVerified: mfa_token => {
      // Make sure there was a previous call
      if (!params.length) return

      const [data] = params

      // Auto retry the request with token
      toast
        .promise(runAsync({ ...data, mfa_token }), toastMsg)
        .then(() => {
          formProps.reset()
          closePasswordModal()
        })
        .catch(twoFAHelper.reChallengeOrExitOnError)
    }
  })

  const { mfa_token } = mfaBlock

  const formProps = useUpdatePasswordForm({
    onSuccess: (_, { password }) => {
      toast
        .promise(
          runAsync({
            password,
            mfa_token
          }),
          toastMsg
        )
        .then(() => {
          closePasswordModal()
        })
    },
    disabled: loading
  })

  // Auto open via query param
  useEffect(() => {
    if (open_change_password?.toLowerCase() !== 'true') return

    closePasswordModal()
    updateQueryParams({ open_change_password: undefined })
  }, [
    open_change_password,
    updateQueryParams,
    passwordModal.modalControls.open,
    closePasswordModal
  ])

  return (
    <>
      <ModalWithHeader title="Update password" modalProps={passwordModal}>
        <UpdatePasswordForm {...formProps} />
      </ModalWithHeader>

      <button
        type="button"
        onClick={passwordModal.modalControls.toggle}
        {...props}
      />

      <MFABlock {...mfaBlock} />
    </>
  )
}

export default ChangePasswordButton
