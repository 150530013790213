import { FC } from 'react'

import { Button } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'

import ExtraIcon from '$icons/ExtraIcon'

import MFABlock from '$blocks/MFABlock'
import FocusModal from '$elements/FocusModal'
import AuthLogoBanner from '$elements/AuthLogoBanner'
import CenterLoader from '$elements/CenterLoader'
import BaseModal, { BaseModalContent } from '$elements/BaseModal'
import ConfirmModal from '$elements/ConfirmModal'

import TOTPRegisterForm from '$hook-forms/TOTPRegisterForm'

import Method from '../Method'
import { UseConfigureTOTPReturn } from './useConfigureTOTP'

import { separator } from '../styles.css'
import {
  content,
  description,
  intro,
  modalContent,
  setupContent,
  setupContentDescription,
  setupContentGap,
  setupContentGapBars,
  setupContentHelpText,
  setupContentHelpTextHighlighted,
  setupContentInfo,
  setupContentIntro,
  setupContentQR,
  setupContentSecret,
  setupContentSecretText,
  setupVerifyContent
} from './styles.css'

type ConfigureTOTPProps = UseConfigureTOTPReturn

const qrIcon = <ExtraIcon name="qr-code" />

export const ConfigureTOTP: FC<ConfigureTOTPProps> = ({
  mainModal,
  hasTotp,
  disabled,
  setup: {
    modal: setupModal,
    onSetupCancel,
    onSetup,
    mfaBlock: setupMFABlock,
    isLoading: isSetupLoading,
    onVerifySubmit,
    showSecret,
    toggleSecret,
    copySecret,
    secret,
    qrSvg
  },
  remove: { mfaBlock: removeMFABlock, modal: removeModal, onRemove }
}) => (
  <>
    <FocusModal modalProps={mainModal} mainClassName={modalContent}>
      <article className={intro}>
        <p className={description}>
          Instead of waiting for text messages, get verification codes from an
          authenticator app. It works even if your phone is offline. You can
          download any authenticator app from Google Play Store or the IOS App
          Store.
        </p>

        {hasTotp && (
          <Button
            styleVariants={{ category: 'icon', kind: 'neutral', type: 'text' }}
            title="Remove Authenticator App"
            disabled={disabled}
            onClick={onRemove}
          >
            <Icon namespace="Trash" />
          </Button>
        )}
      </article>

      <article className={content}>
        <Method title="Authenticator App" icon={qrIcon}>
          <Button
            styleVariants={{
              type: 'text',
              kind: hasTotp ? 'warning' : 'primary'
            }}
            disabled={disabled}
            onClick={onSetup}
          >
            {qrIcon}
            {hasTotp ? 'Change Authenticator App' : 'Set Up Authenticator App'}
          </Button>
        </Method>

        <hr className={separator} />
      </article>
    </FocusModal>

    <BaseModal {...setupModal}>
      <BaseModalContent className={setupContent}>
        <AuthLogoBanner />

        <div className={setupContentInfo}>
          <div className={setupContentIntro}>
            <h4>{setupModal.modalOptions?.title}</h4>
            <p className={setupContentDescription}>
              Scan the QR code below with your authenticator app to set up
              two-factor authentication.
            </p>
          </div>

          {isSetupLoading ? (
            <CenterLoader />
          ) : (
            <>
              {qrSvg && (
                <div
                  dangerouslySetInnerHTML={{ __html: qrSvg }}
                  className={setupContentQR}
                />
              )}

              {showSecret && (
                <div className={setupContentSecret}>
                  <p className={setupContentSecretText}>{secret}</p>

                  <Button
                    onClick={copySecret}
                    styleVariants={{
                      type: 'text',
                      size: 'small',
                      kind: 'neutral'
                    }}
                  >
                    <Icon namespace="Copy" />
                    Copy and use in your app!
                  </Button>
                </div>
              )}

              <div className={setupContentHelpText}>
                {showSecret ? (
                  <>
                    Want to scan again?{' '}
                    <button
                      type="button"
                      className={setupContentHelpTextHighlighted}
                      onClick={toggleSecret}
                    >
                      Scan QR Code
                    </button>
                  </>
                ) : (
                  <>
                    Unable to scan? Try with{' '}
                    <button
                      type="button"
                      className={setupContentHelpTextHighlighted}
                      onClick={toggleSecret}
                    >
                      setup key
                    </button>{' '}
                    to configure!
                  </>
                )}
              </div>

              <div className={setupContentGap}>
                <hr className={setupContentGapBars} />
                <span>Then</span>
                <hr className={setupContentGapBars} />
              </div>

              <div className={setupVerifyContent}>
                <TOTPRegisterForm
                  disabled={disabled}
                  onSuccess={onVerifySubmit}
                />

                <Button
                  styleVariants={{
                    type: 'text',
                    kind: 'neutral',
                    size: 'small'
                  }}
                  onClick={onSetupCancel}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </div>
      </BaseModalContent>
    </BaseModal>

    <ConfirmModal
      {...removeModal}
      cancelButtonProps={{ disabled: disabled }}
      confirmButtonProps={{ disabled: disabled }}
    />

    <MFABlock {...setupMFABlock} />
    <MFABlock {...removeMFABlock} />
  </>
)

export default ConfigureTOTP
