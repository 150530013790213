import { CommonAPI, Token } from './common'
import {
  MFAChallengeRequest,
  MFAChallengeResponse,
  MFAInfoResponse,
  MFARegisterTOTPRemoveRequest,
  MFARegisterTOTPRequest,
  MFARegisterTOTPRequestResponse,
  MFARegisterTOTPSetupRequest,
  MFARetryRequest,
  MFARetryResponse,
  MFASetPreferredFactorRequest,
  MFAVerifyRequest,
  MFAVerifyResponse
} from './types'

import { baseURL } from './constants'

export class MfaAuthAPI extends CommonAPI {
  constructor(token: Token) {
    super({ baseURL })

    this.filterRequestPayload()

    this.updateToken(token)
  }

  public mfaInfoRequest = () => {
    return this.api.get<MFAInfoResponse>('/mfa/info')
  }

  public mfaChallenge = (data: MFAChallengeRequest) => {
    return this.api.post<MFAChallengeResponse>('/mfa/challenge', data)
  }

  public mfaRetry = (data: MFARetryRequest) => {
    return this.api.post<MFARetryResponse>('/mfa/resend', data)
  }

  public mfaVerify = (data: MFAVerifyRequest) => {
    return this.api.post<MFAVerifyResponse>('/mfa/verify', data)
  }

  public mfaSetPreferredFactor = ({ type }: MFASetPreferredFactorRequest) => {
    return this.api.put(`/mfa/set-preferred-factor/${type}`)
  }

  public mfaRegisterTOTPRequest = (data: MFARegisterTOTPRequest) => {
    return this.api.post<MFARegisterTOTPRequestResponse>(
      '/mfa/register/totp/request',
      data
    )
  }

  public mfaRegisterTOTPRemove = (data: MFARegisterTOTPRemoveRequest) => {
    return this.api.post('/mfa/register/totp/remove', data)
  }

  public mfaRegisterTOTPSetup = (data: MFARegisterTOTPSetupRequest) => {
    return this.api.put('/mfa/register/totp/setup', data)
  }
}
