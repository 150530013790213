import { useRequest } from 'ahooks'
import { toast } from 'react-hot-toast'

import { MobileOTPVerifyRequest } from '$api/types'
import { AnonymousAuthAPI } from '$api/anonymous-auth'

import { addToken } from '$store/session'

import { useSuccessLogin } from './useSuccessLogin'

const { mobileOTPVerify, errorMessageResolver, successResolver } =
  new AnonymousAuthAPI(null)

export const useMobileOTPVerify = () => {
  const onLoginSuccess = useSuccessLogin()

  const { loading, runAsync } = useRequest(mobileOTPVerify, { manual: true })

  const verify = async (data: MobileOTPVerifyRequest) => {
    const {
      data: { auth_token }
    } = await toast
      .promise(runAsync(data), {
        loading: 'Verifying..',
        error: e => errorMessageResolver(e, 'Invalid credential'),
        success: 'OTP requested'
      })
      .then(successResolver)

    const id = addToken(auth_token)

    onLoginSuccess(id)
  }

  return { verify, loading }
}

export default useMobileOTPVerify
