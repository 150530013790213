import { useRequest } from 'ahooks'
import toast from 'react-hot-toast'

import api from '$model/api'

import { errorMessageResolver } from '$services/api/common'
import { MFASetPreferredFactorRequest } from '$services/api/types'

import useSignal from './useSignal'

type UseSetPreferredMFA = {
  onSuccess: VoidFunction
}

export const useSetPreferredMFA = (options?: UseSetPreferredMFA) => {
  const { onSuccess } = { ...options }
  const {
    mfaAuth: { mfaSetPreferredFactor }
  } = useSignal(api)
  const { loading, runAsync } = useRequest(mfaSetPreferredFactor, {
    manual: true,
    onSuccess
  })

  const set = (type: MFASetPreferredFactorRequest['type']) => {
    return toast.promise(runAsync({ type }), {
      loading: 'Updating...',
      success: 'Preferred MFA factor updated successfully!',
      error: errorMessageResolver
    })
  }

  return {
    loading,
    set
  }
}

export default useSetPreferredMFA
