import { CF_TURNSTILE_HEADER_KEY } from '$constants'

import { CommonAPI, Token } from './common'
import {
  ForgotPassword2FARequest,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  Login2FARequest,
  Login2FAResponse,
  LoginRequest,
  LoginResponse,
  MobileOTPRequestRequest,
  MobileOTPRequestResponse,
  MobileOTPResendRequest,
  MobileOTPResendResponse,
  MobileOTPVerifyRequest,
  MobileOTPVerifyResponse,
  ResetPasswordRequest
} from './types'

import { baseURL } from './constants'

export class AnonymousAuthAPI extends CommonAPI {
  constructor(token: Token) {
    super({ baseURL })

    this.filterRequestPayload()

    this.updateToken(token)
  }

  public login = ({ cf_turnstile_token, ...data }: LoginRequest) => {
    return this.api.post<LoginResponse>('/login', data, {
      headers: { [CF_TURNSTILE_HEADER_KEY]: cf_turnstile_token }
    })
  }

  public login2FA = (data: Login2FARequest) => {
    return this.api.post<Login2FAResponse>('/login/2fa', data)
  }

  public forgotPassword = (data: ForgotPasswordRequest) => {
    return this.api.post<ForgotPasswordResponse>('/forgot-password', data)
  }

  public forgotPassword2FA = (data: ForgotPassword2FARequest) => {
    return this.api.post('/forgot-password/2fa', data)
  }

  public resetPassword = (data: ResetPasswordRequest) => {
    return this.api.post('/reset-password', data)
  }

  public mobileOTPRequest = ({
    cf_turnstile_token,
    ...data
  }: MobileOTPRequestRequest) => {
    return this.api.post<MobileOTPRequestResponse>('/login/mobile', data, {
      headers: { [CF_TURNSTILE_HEADER_KEY]: cf_turnstile_token }
    })
  }

  public mobileOTPResend = (data: MobileOTPResendRequest) => {
    return this.api.post<MobileOTPResendResponse>('/login/mobile/resend', data)
  }

  public mobileOTPVerify = (data: MobileOTPVerifyRequest) => {
    return this.api.post<MobileOTPVerifyResponse>('/login/mobile/verify', data)
  }

  public emailVerificationConfirm = <T extends object>(params: T) => {
    return this.api.post('/profile/email/verification/confirm', undefined, {
      params
    })
  }
}
