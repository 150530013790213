import { FC, PropsWithChildren } from 'react'

import CenterLoader from '$elements/CenterLoader'

import useSignal from '$actions/useSignal'

import { profile } from '$store/profile'

/**
 * Wait until profile data arrived.
 */
export const ProfileWaiter: FC<PropsWithChildren> = ({ children }) => {
  const info = useSignal(profile)
  const isLoading = info?.loading
  const hasData = !!info?.data

  if (!isLoading && hasData) return children

  return <CenterLoader />
}

export default ProfileWaiter
