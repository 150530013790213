import { useRequest } from 'ahooks'

import { Button } from '@genie-fintech/ui/components'

import useRouter from '$actions/useRouter'

import Link from '$elements/Link'
import CenterLoader from '$elements/CenterLoader'
import ExtraIcon from '$icons/ExtraIcon'
import { description, title } from '$blocks/common/auth.css'

import { refreshStates } from '$store/profiles'

import { errorMessageResolver } from '$services/api/common'
import { AnonymousAuthAPI } from '$services/api/anonymous-auth'

import { container, icon, flexColumn } from './styles.css'

const { emailVerificationConfirm } = new AnonymousAuthAPI(null)

export const EmailVerification = () => {
  const { queryParams } = useRouter()

  const { loading, error } = useRequest(
    () => emailVerificationConfirm(queryParams),
    {
      onSuccess: refreshStates
    }
  )

  const errrorMsg = errorMessageResolver(error)

  if (loading) return <CenterLoader />

  return (
    <article className={container}>
      <ExtraIcon
        name={error ? 'alert-triangle' : 'email-tick'}
        className={icon}
      />

      <article className={flexColumn} style={{ gap: 8 }}>
        <h1 className={title}>{error ? errrorMsg : 'Email is verified!'}</h1>
        {!error && (
          <p className={description}>
            Your email has been successfully verified! You can now go to access
            the Carro SSO platform.
          </p>
        )}
      </article>

      <Link to="profileDetail" options={{ replace: true }}>
        <Button>Go to Profile</Button>
      </Link>
    </article>
  )
}

export default EmailVerification
