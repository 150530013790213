import { useRequest } from 'ahooks'
import { mapValues, toNumber } from 'lodash-es'
import { toast } from 'react-hot-toast'

import { AnonymousAuthAPI } from '$api/anonymous-auth'

import { MobileOTPRequestFormProps } from '$hook-forms/MobileOTPRequestForm'

import useRouter from './useRouter'
import { MobileOTPRequestRequest } from '$api/types'

const { mobileOTPRequest, errorMessageResolver, successResolver } =
  new AnonymousAuthAPI(null)

type OnSuccess = MobileOTPRequestFormProps['onSuccess']

export const useMobileOTPRequest = (): MobileOTPRequestFormProps => {
  const { redirect } = useRouter()

  const { loading, runAsync } = useRequest(mobileOTPRequest, { manual: true })

  const onSuccess: OnSuccess = async (
    reset,
    { is_whatsapp, cf_turnstile_token, ...data }
  ) => {
    const payload: MobileOTPRequestRequest = {
      ...mapValues(data, toNumber),
      send_via: is_whatsapp ? 'whatsapp' : 'sms',
      cf_turnstile_token
    }

    const {
      data: { reference_number }
    } = await toast
      .promise(runAsync(payload), {
        loading: 'Requesting..',
        error: e => errorMessageResolver(e, 'Invalid credential'),
        success: 'OTP requested'
      })
      .then(successResolver)

    reset()

    redirect('authSmsOtpVerify', ({ queryParams }) => ({
      queryParams: {
        // Carry current parameters
        ...queryParams,

        // Pass OTP ref number for retry
        reference_number
      }
    }))
  }

  return { onSuccess, disabled: loading }
}

export default useMobileOTPRequest
