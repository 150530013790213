import { OTPInputProps } from '$elements/OTPInput'

type UseOTPBlockOptions = {
  otpInput: OTPInputProps
  refCode?: string
  onResend?: VoidFunction
  timerActive: boolean
  timerSeconds: number
  title: string
  description: string
}

export const useOTPBlock = (props: UseOTPBlockOptions) => props

export default useOTPBlock
