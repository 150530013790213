import { FC } from 'react'
import { mapValues, toNumber } from 'lodash-es'

import { Icon } from '@genie-fintech/ui/icons'
import { Button } from '@genie-fintech/ui/components'

import { authMethods } from '$constants/auth'
import { DEFAULT_OTP_RETRY_TIMER } from '$constants'

import useRouter from '$actions/useRouter'
import useTimer from '$actions/useTimer'
import useOTPInput from '$actions/useOTPInput'
import useMobileOTPResend from '$actions/useMobileOTPResend'
import useMobileOTPVerify from '$actions/useMobileOTPVerify'

import { backLink } from '$styles/atoms.css'

import OTPInput from '$elements/OTPInput'
import Link from '$elements/Link'

import {
  container,
  description,
  field,
  highlightedText,
  inner,
  main,
  nav,
  reSendText
} from './styles.css'

export const SmsOtpVerifyBlock: FC = () => {
  const {
    reset: resetTimer,
    active: timerActive,
    seconds
  } = useTimer(DEFAULT_OTP_RETRY_TIMER)

  const { verify, loading: loadingVerify } = useMobileOTPVerify()
  const { resend, loading: loadingResend } = useMobileOTPResend()

  const {
    queryParams: { reference_number = '', return_to }
  } = useRouter()

  const isLoading = loadingVerify || loadingResend
  const otpInput = useOTPInput({
    disabled: isLoading,
    className: field,
    onFilled: (otp, reset) => {
      const data = mapValues({ otp, reference_number }, toNumber)

      verify(data).finally(reset)
    }
  })

  return (
    <section className={container}>
      <nav className={nav}>
        <Link
          to="authLogin"
          options={() => ({
            queryParams: { return_to, auth_method: authMethods[1] }
          })}
          className={backLink}
        >
          <Icon namespace="Backward" /> Back
        </Link>
      </nav>

      <div className={inner}>
        <main className={main}>
          <article className={description}>
            <p>
              We have sent you an SMS code to your phone number. Ref: (
              <span className={highlightedText}>{reference_number}</span>)
            </p>
          </article>

          <OTPInput {...otpInput} />

          <article>
            {timerActive ? (
              <p className={reSendText}>
                Resend in <span className={highlightedText}>{seconds}s</span>
              </p>
            ) : (
              <Button
                styleVariants={{ size: 'small' }}
                onClick={() => {
                  const data = mapValues({ reference_number }, toNumber)

                  resend(data).finally(resetTimer)
                }}
                disabled={isLoading}
              >
                Resend SMS
              </Button>
            )}
          </article>
        </main>
      </div>
    </section>
  )
}

export default SmsOtpVerifyBlock
