import { useRequest } from 'ahooks'
import toast from 'react-hot-toast'

import { useConfirmModal } from '$contexts/Modal'
import useSignal from '$actions/useSignal'
import useMFABlock from '$actions/useMFABlock'
import use2FAHelper from '$actions/use2FAHelper'

import { asyncSuccessResolver } from '$app/utilities'
import { ToastMessage } from '$app/types'

import api from '$model/api'
import { errorMessageResolver, successResolver } from '$services/api/common'

export type UseRemoveOptions = {
  onRemoveed?: () => void
}

export type UseRemoveReturn = ReturnType<typeof useRemove>

const removeReqToastMsg: ToastMessage = {
  loading: 'Removeing..',
  error: errorMessageResolver,
  success: 'TOTP removeed.'
}

export const useRemove = ({ onRemoveed }: UseRemoveOptions) => {
  const modal = useConfirmModal({
    onConfirm: async close => {
      await toast.promise(removeReq.runAsync({ mfa_token }), removeReqToastMsg)

      close()
    },
    onClose: () => {
      reset()
      mfaBlock.exit()
    },
    title: 'Confirm to Remove TOTP'
  })

  const { mfaAuth: apiInstance } = useSignal(api)
  const { mfaRegisterTOTPRemove } = apiInstance

  const removeReq = useRequest(
    asyncSuccessResolver(mfaRegisterTOTPRemove, successResolver),
    {
      manual: true,
      onError: err => {
        if (twoFAHelper.is2FAError(err)) {
          mfaBlock.modalControls.open()
        }
      },
      onSuccess: () => {
        modal.modalControls.close()
        onRemoveed?.()
      }
    }
  )

  const isLoading = removeReq.loading

  const twoFAHelper = use2FAHelper({ getMfaBlock: () => mfaBlock })
  const available_factors = twoFAHelper.getFactorsByError(removeReq.error)

  const mfaBlock = useMFABlock({
    apiInstance,
    data: { available_factors },
    onCancelled: modal.modalControls.close,
    onVerified: mfa_token => {
      const { params, runAsync } = removeReq

      // Make sure there was a previous call
      if (!params.length) return

      // Auto retry the request with token
      toast
        .promise(runAsync({ mfa_token }), removeReqToastMsg)
        .catch(twoFAHelper.reChallengeOrExitOnError)
    }
  })

  const { mfa_token } = mfaBlock

  const reset = () => {
    removeReq.cancel()
    removeReq.mutate()
  }

  const onRemove = () => {
    modal.modalControls.open()
  }

  return {
    modal,
    onRemove,
    isLoading,
    mfaBlock
  }
}

export default useRemove
