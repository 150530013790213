import { useRef } from 'react'
import { useRequest } from 'ahooks'
import toast from 'react-hot-toast'

import { asyncSuccessResolver } from '$app/utilities'
import { ToastMessage } from '$app/types'

import { errorMessageResolver, successResolver } from '$services/api/common'
import { addToken } from '$store/session'
import { MfaAuthAPI } from '$services/api/mfa-auth'
import { Token } from '$store/session/tokens'

import { LoginFormProps } from '$hook-forms/LoginForm'

import { AnonymousAuthAPI } from '$api/anonymous-auth'
import { parseNo2faGuardStagedContentResolver } from '$api/resolvers'

import { useSuccessLogin } from './useSuccessLogin'
import useMFABlock from './useMFABlock'
import use2FAHelper from './use2FAHelper'

const toastMsg = {
  loading: 'Verifying..',
  error: e => errorMessageResolver(e, 'Invalid credential'),
  success: 'Successfully logged in'
} satisfies ToastMessage

export const useEmailLogin = () => {
  const { current: anonymousApi } = useRef(new AnonymousAuthAPI(null))
  const { current: mfaApi } = useRef(new MfaAuthAPI(null))

  const setAuthToken: typeof anonymousApi.updateToken = token => {
    anonymousApi.updateToken(token)
    mfaApi.updateToken(token)
  }

  const { login, login2FA } = anonymousApi

  const onLoginSuccess = useSuccessLogin()

  const loginRequest = useRequest(
    asyncSuccessResolver(login, successResolver),
    { manual: true }
  )
  const login2FARequest = useRequest(
    asyncSuccessResolver(login2FA, successResolver),
    { manual: true }
  )

  const twoFAHelper = use2FAHelper({ getMfaBlock: () => mfaBlock })
  const available_factors = twoFAHelper.getFactorsByContent(
    loginRequest.data?.data
  )

  const mfaBlock = useMFABlock({
    apiInstance: mfaApi,
    data: { available_factors },
    onVerified: mfa_token => {
      const { runAsync } = login2FARequest

      toast
        .promise(runAsync({ mfa_token }), toastMsg)
        .then(({ data: { auth_token } }) => {
          setAuthToken(null)
          onTokenSuccess(auth_token)
        })
        .catch(twoFAHelper.reChallengeOrExitOnError)
    }
  })

  const onTokenSuccess = (token: Token) => {
    const id = addToken(token)

    onLoginSuccess(id)
  }

  const onFormSubmit: LoginFormProps['onSuccess'] = async (reset, data) => {
    const { runAsync } = loginRequest

    const { data: result } = await toast.promise(runAsync(data), {
      ...toastMsg,
      success: ({ data, message }) => {
        return twoFAHelper.is2FAContent(data) ? message : toastMsg.success
      }
    })

    reset()

    const is2FAPerformed = twoFAHelper.perform2FAActionByContent(
      result,
      ({ auth_token }) => {
        setAuthToken(auth_token)
        mfaBlock.modalControls.open()
      }
    )

    if (is2FAPerformed) return

    const { auth_token } = { ...parseNo2faGuardStagedContentResolver(result) }

    if (auth_token) {
      onTokenSuccess(result.auth_token)
    }
  }

  const loginForm: LoginFormProps = {
    onSuccess: onFormSubmit,
    disabled: loginRequest.loading || login2FARequest.loading
  }

  return {
    loginForm,
    onTokenSuccess,
    mfaBlock
  }
}

export default useEmailLogin
