import { MouseEventHandler, useId, useState } from 'react'

import { TRANSITIONS } from '@genie-fintech/ui/constants'

import useInert from '$browser/useInert'
import { wait } from '$app/utilities'
import useNoScrollY from '$browser/useNoScrollY'

export type UseModalOptions = {
  title?: string
  onClose?: VoidFunction
  disabledOverlayClick?: boolean
  onOverlayClick?: (closing: Promise<void>) => void
}

const createAsyncControl = <T extends (...args: Parameters<T>) => void>(
  method: T,
  skipWaiting: boolean
) => {
  const control = async (...args: Parameters<T>) => {
    method(...args)

    if (skipWaiting) return

    await wait(TRANSITIONS.GENERAL_DURATION)
  }

  return control
}

export const useModal = (modalOptions?: UseModalOptions) => {
  const id = useId()

  const {
    onClose,
    disabledOverlayClick,
    onOverlayClick: onOverlayClickCallback
  } = {
    ...modalOptions
  }
  const [modalStatus, setStatus] = useState(false)

  const modalControls = {
    open: () => setStatus(true),
    close: () => {
      if (!modalStatus) return

      setStatus(false)

      onClose?.()
    },
    toggle: () => {
      const status = !modalStatus
      setStatus(status)

      if (!status) onClose?.()
    },
    set: (status => {
      setStatus(status)

      if (!status) onClose?.()
    }) satisfies typeof setStatus
  }

  const modalControlsAsync = {
    set: createAsyncControl(modalControls.set, false),
    open: createAsyncControl(modalControls.open, modalStatus === true),
    close: createAsyncControl(modalControls.close, modalStatus === false)
  }

  const modal = [modalStatus, modalControls, modalControlsAsync] as const
  const modalRef = useInert(!modalStatus)

  const onOverlayClick: MouseEventHandler = e => {
    if (disabledOverlayClick) return

    if (e.target !== e.currentTarget) return

    const action = modalControlsAsync.close()

    onOverlayClickCallback?.(action)
  }

  useNoScrollY(`base-modal-${id}`, modalStatus)

  return {
    modal,
    modalRef,
    modalStatus,
    modalOptions,
    modalControls,
    modalControlsAsync,
    onOverlayClick,
    disabledOverlayClick
  }
}

export default useModal
