import { useController, ControllerRenderProps } from 'react-hook-form'

import SelectField, {
  SelectFieldProps as SelectFieldProps
} from '$fields/Select'
import { HookField } from '@genie-fintech/ui/components/hook-fields/common/types'
import { resolveErrorMessage } from '@genie-fintech/ui/components/hook-fields/common/functions'

type SelectProps = Required<SelectFieldProps>['selectProps']

export type BaseSelectProps = Omit<
  SelectFieldProps,
  'selectProps' | 'selectRef'
> & {
  selectProps?: Omit<SelectProps, keyof ControllerRenderProps>
}

export const SelectHookField: HookField<BaseSelectProps> = ({
  name,
  control,
  disabled,
  controllerOptions,
  ...fieldProps
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    disabled,
    ...controllerOptions
  })

  const { value = '', ref: selectRef, ...controlledFieldProps } = field

  const errorMessage = resolveErrorMessage(fieldState.error)
  const hasError = !!errorMessage

  const fieldPropBindings: SelectFieldProps = {
    disabled,
    error: hasError,
    message: errorMessage,
    ...fieldProps,
    selectRef,
    selectProps: {
      ...fieldProps.selectProps,
      ...controlledFieldProps,
      value
    }
  }

  return <SelectField {...fieldPropBindings} />
}

export default SelectHookField
