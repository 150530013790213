import { FC } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Button } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'

import { TypeSubmitForm } from '$hook-forms/types'
import { requiredString } from '$hook-forms/general-validators'

import { container } from './styles.css'

const Schema = z.object({
  code: requiredString
})
const resolver = zodResolver(Schema)

type TFields = z.infer<typeof Schema>

const defaultValues: TFields = {
  code: ''
}

export type TOTPRegisterFormFields = TFields

export type TOTPRegisterFormProps = TypeSubmitForm<TFields>

export const TOTPRegisterForm: FC<TOTPRegisterFormProps> = ({
  onSuccess,
  disabled
}) => {
  const { control, reset, handleSubmit, formState } = useForm({
    resolver,
    defaultValues,
    mode: 'all'
  })

  const isInvalid = disabled || !!Object.keys(formState.errors).length

  return (
    <form
      className={container}
      onSubmit={handleSubmit((...handler) => onSuccess(reset, ...handler))}
      noValidate
      autoComplete="off"
    >
      <BaseText
        required
        control={control}
        name="code"
        label="Enter the one-time code from Authenticator App"
        disabled={disabled}
        inputProps={{ type: 'number', inputMode: 'numeric' }}
      />

      <Button
        type="submit"
        disabled={isInvalid}
        styleVariants={{ size: 'small', kind: 'primary' }}
      >
        Enable Authenticator Application
      </Button>
    </form>
  )
}

export default TOTPRegisterForm
