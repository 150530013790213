import { zodResolver } from '@hookform/resolvers/zod'
import { defaultValues, schema, TFields } from './schema'
import { useForm } from 'react-hook-form'
import { TypeSubmitForm } from '$hook-forms/types'
import { useRef } from 'react'

type Props = TypeSubmitForm<TFields>

export const useUpdateProfileBasicInfoForm = (props: Props) => {
  const submitInputRef = useRef<HTMLInputElement>(null)

  const { disabled } = props
  const resolver = zodResolver(schema)

  const { control, reset, handleSubmit, formState } = useForm({
    resolver,
    defaultValues,
    mode: 'all'
  })

  const isInvalid = disabled || !!Object.keys(formState.errors).length

  return {
    ...props,
    control,
    reset,
    handleSubmit,
    isInvalid,
    submitInputRef
  }
}

export default useUpdateProfileBasicInfoForm

export type UseUpdateProfileBasicInfoForm = ReturnType<
  typeof useUpdateProfileBasicInfoForm
>
