import { FC } from 'react'

import { Portal } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'

import BaseModal, { BaseModalContent } from '$elements/BaseModal'

import useMFABlock from '$actions/useMFABlock'

import Choice from './Choice'
import OTPBlock from '../OTPBlock'

import { closeButton, content } from './styles.css'

export type MFABlockProps = ReturnType<typeof useMFABlock>

const Block: FC<MFABlockProps> = ({
  modalProps,
  data,
  state,
  disabled,
  onSelect,
  onExit,
  otpBlock
}) => (
  <BaseModal {...modalProps}>
    <BaseModalContent className={content}>
      <button type="button" className={closeButton} onClick={onExit}>
        <Icon namespace="Backward" /> Back
      </button>

      {state == 'choice' && (
        <Choice data={data} onClick={onSelect} disabled={disabled} />
      )}

      {state == 'otp' && <OTPBlock {...otpBlock} />}
    </BaseModalContent>
  </BaseModal>
)

export const MFABlock: FC<MFABlockProps> = props => (
  <Portal>
    <Block {...props} />
  </Portal>
)

export default MFABlock
