import { useBoolean, useRequest } from 'ahooks'

import useSignal from '$actions/useSignal'

import { asyncSuccessResolver } from '$app/utilities'
import { successResolver } from '$services/api/common'

import session from '$store/session'
import { api } from '$model/api'

export const useMFAInfo = () => {
  const { current: sessionID } = useSignal(session)

  const {
    mfaAuth: { mfaInfoRequest }
  } = useSignal(api)

  // Track initial loading
  const [isDirty, { setTrue }] = useBoolean()

  const {
    data,
    loading,
    error,
    refreshAsync: refresh
  } = useRequest(asyncSuccessResolver(mfaInfoRequest, successResolver), {
    // https://github.com/alibaba/hooks/issues/2356#issue-1977838612
    // cacheKey: `${sessionID}:mfa-info`,

    refreshDeps: [sessionID],
    refreshOnWindowFocus: true,
    onFinally: setTrue
  })
  const { registered_factors = [] } = { ...data?.data }

  const isLoading = !isDirty && loading
  const hasError = !!error === true
  const isEmpty = registered_factors.length === 0 || hasError

  return {
    isLoading,
    isEmpty,
    registered_factors,
    refresh
  }
}

export default useMFAInfo
