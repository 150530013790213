import { FC } from 'react'

import { valueOrUndefined } from '$app/utilities'

import useLoginDevices from '$actions/useLoginDevices'
import usePageClass from '$browser/usePageClass'

import LoginDevices from '$blocks/LoginDevices'
import CenterLoader from '$elements/CenterLoader'

import PageHead from '$layouts/AuthenticatedLayout/PageHead'
import PageRegion from '$layouts/AuthenticatedLayout/PageRegion'
import PageContents from '$layouts/AuthenticatedLayout/PageContents'

import { list } from './styles.css'

const pageName = 'LoginHistory'

export const LoginHistory: FC = () => {
  usePageClass({ name: pageName })

  const {
    devices,
    isEmpty: isDeviceEmpty,
    isLoading: isDevicesLoading
  } = useLoginDevices()

  return (
    <>
      <PageHead
        title="Manage All Devices"
        description="Manage and review user’s login devices for efficient system administration."
      />

      <PageRegion>
        <PageContents
          title="Login Devices"
          description="These are the devices that user logged in. There might be multiple activity sessions from the same devices."
          containerProps={{
            'data-testid': valueOrUndefined(isDeviceEmpty, 'no-devices')
          }}
          mainClassName={list}
        >
          {isDevicesLoading ? (
            <CenterLoader />
          ) : (
            <LoginDevices devices={devices} />
          )}
        </PageContents>
      </PageRegion>
    </>
  )
}

export default LoginHistory
