import { useRef } from 'react'

import useIsMounted from '$browser/useIsMounted'

import { useSidebarActions } from './useSidebarActions'

export const useProviderValue = () => {
  const ready = useIsMounted()

  const containerRef = useRef(null)
  const pageHeadRef = useRef(null)
  const pageRegionRef = useRef(null)

  const sidebarActions = useSidebarActions()

  return { containerRef, pageHeadRef, pageRegionRef, ready, sidebarActions }
}

export type Value = ReturnType<typeof useProviderValue>

export default useProviderValue
