import { FC } from 'react'

import { Spinner } from '@genie-fintech/ui/components'

export const CenterLoader: FC = () => (
  <Spinner
    color="neutral.100"
    style={{
      width: 'auto',
      display: 'block',
      alignSelf: 'center',
      justifySelf: 'center',
      marginLeft: 'auto',
      marginRight: 'auto'
    }}
  />
)

export default CenterLoader
