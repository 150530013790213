import { FC } from 'react'
import { Icon } from '@genie-fintech/ui/icons'

import { Button, ButtonProps } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

import { getPhone } from '$app/utilities'

import usePageClass from '$browser/usePageClass'
import useRouter from '$actions/useRouter'
import useSignal from '$actions/useSignal'
import useMFAInfo from '$actions/useMFAInfo'
import useSetPreferredMFA from '$actions/useSetPreferredMFA'

import PageHead from '$layouts/AuthenticatedLayout/PageHead'
import PageRegion from '$layouts/AuthenticatedLayout/PageRegion'
import PageContents from '$layouts/AuthenticatedLayout/PageContents'
import { useAuthenticatedLayout } from '$layouts/AuthenticatedLayout/context'

import CenterLoader from '$elements/CenterLoader'

import ExtraIcon from '$icons/ExtraIcon'

import { profile, refreshState } from '$store/profile'

import Method from './Method'

import useConfigureTOTP from './ConfigureTOTP/useConfigureTOTP'
import ConfigureTOTP from './ConfigureTOTP'

import { content, separator, totpSetupActionLabel } from './styles.css'

const pageName = 'MFASetting'

const buttonTheme: ButtonProps['styleVariants'] = {
  type: 'text'
}

const buttonIcon = <Icon namespace="Right" />

export const MFASetting: FC = () => {
  usePageClass({ name: pageName })

  const {
    sidebarActions: { emailVerify, phoneVerify }
  } = useAuthenticatedLayout()

  const { info, redirect } = useRouter()
  const { title } = { ...info?.meta }

  const profileData = useSignal(profile)

  const { email, is_phone_verified, is_email_verified } = {
    ...profileData?.data
  }
  const phone = getPhone({ ...profileData?.data })

  const mfaInfo = useMFAInfo()
  const configureTOTP = useConfigureTOTP({
    mfaInfo,
    setupOptions: { onUpdated: refreshState },
    removeOptions: { onRemoveed: refreshState }
  })

  const { set: setPreferredMFA, loading: preferredMFALoading } =
    useSetPreferredMFA({ onSuccess: mfaInfo.refresh })

  const { hasTotp } = configureTOTP
  const isLoading = !!(mfaInfo.isLoading || profileData?.loading)

  const isEmailPreferred = mfaInfo.registered_factors.some(
    ({ is_preferred, type }) => type === 'email_otp' && is_preferred
  )
  const isSMSPreferred = mfaInfo.registered_factors.some(
    ({ is_preferred, type }) => type === 'sms_otp' && is_preferred
  )
  const isTotpPreferred = mfaInfo.registered_factors.some(
    ({ is_preferred, type }) => type === 'totp' && is_preferred
  )

  const registerEmail = () => {
    redirect('profileDetail', {
      queryParams: {
        block_action: 'ChangeEmailBlock'
      }
    })
  }
  const registerPhone = () => {
    redirect('profileDetail', {
      queryParams: {
        block_action: 'ChangePhoneBlock'
      }
    })
  }

  const PreferButton: FC<ButtonProps> = props => (
    <Button
      styleVariants={buttonTheme}
      disabled={preferredMFALoading}
      {...props}
    >
      Set as preferred method
    </Button>
  )

  return (
    <>
      <PageHead
        title={title}
        description="It provides more than one form of authentication to access an account or application."
      />

      <PageRegion>
        <PageContents
          title="Mfa Methods"
          description="Make sure you can access your account by keeping this information up to date and adding more sign-in options."
          mainClassName={content}
          mainProps={{ 'data-loading': trueOrUndefined(isLoading) }}
        >
          {isLoading ? (
            <CenterLoader />
          ) : (
            <>
              <Method
                title="Email OTP"
                description={email}
                isPreferred={isEmailPreferred}
                icon={<Icon namespace="Email" />}
              >
                {email ? (
                  <>
                    {!is_email_verified && (
                      <Button
                        styleVariants={{ kind: 'warning', type: 'outlined' }}
                        onClick={emailVerify.request}
                      >
                        <Icon namespace="Warning" />
                        Verify Email
                      </Button>
                    )}

                    {is_email_verified && !isEmailPreferred && (
                      <PreferButton
                        onClick={() => setPreferredMFA('email_otp')}
                      />
                    )}
                  </>
                ) : (
                  <Button
                    styleVariants={{ kind: 'neutral', type: 'text' }}
                    onClick={registerEmail}
                  >
                    <Icon namespace="Add" />
                    Add email
                  </Button>
                )}
              </Method>

              <hr className={separator} />

              <Method
                title="SMS OTP"
                isPreferred={isSMSPreferred}
                description={phone}
                icon={<ExtraIcon name="mobile-phone" />}
              >
                {phone ? (
                  <>
                    {!is_phone_verified && (
                      <Button
                        styleVariants={{ kind: 'warning', type: 'text' }}
                        onClick={phoneVerify.request}
                      >
                        <Icon namespace="Warning" />
                        Verify phone number
                      </Button>
                    )}

                    {is_phone_verified && !isSMSPreferred && (
                      <PreferButton
                        onClick={() => setPreferredMFA('sms_otp')}
                      />
                    )}
                  </>
                ) : (
                  <Button
                    styleVariants={{ kind: 'neutral', type: 'outlined' }}
                    onClick={registerPhone}
                  >
                    <Icon namespace="Add" />
                    Add phone number
                  </Button>
                )}
              </Method>

              <hr className={separator} />

              <Method
                title="Authenticator App"
                isPreferred={isTotpPreferred}
                icon={<ExtraIcon name="qr-code" />}
              >
                {hasTotp ? (
                  <>
                    {!isTotpPreferred && (
                      <PreferButton onClick={() => setPreferredMFA('totp')} />
                    )}

                    <Button
                      styleVariants={buttonTheme}
                      onClick={configureTOTP.mainModal.modalControls.toggle}
                    >
                      Update
                      {buttonIcon}
                    </Button>
                  </>
                ) : (
                  <>
                    <div className={totpSetupActionLabel}>
                      <Icon namespace="Warning" color="warning.default" />
                      Add authenticator app
                    </div>

                    <Button
                      styleVariants={{ category: 'icon', ...buttonTheme }}
                      onClick={configureTOTP.mainModal.modalControls.toggle}
                    >
                      {buttonIcon}
                    </Button>
                  </>
                )}
              </Method>
            </>
          )}
        </PageContents>
      </PageRegion>

      <ConfigureTOTP {...configureTOTP} />
    </>
  )
}

export default MFASetting
