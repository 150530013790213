import { useState } from 'react'
import { useRequest } from 'ahooks'
import { toast } from 'react-hot-toast'

import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'

import { useModal } from '$contexts/Modal'
import useSignal from '$actions/useSignal'

import BaseModal, { BaseModalContent } from '$elements/BaseModal'

import api from '$model/api'
import { profile, refreshState } from '$store/profile'
import { timezones, Timezones } from '$store/settings'
import { errorMessageResolver } from '$api/common'

import Choice from './Choice'
import Confirm from './Confirm'

import { iconStyle, container, modalContent } from './styles.css'

type Timezone = Timezones[number]

const request = (data: { timezone: string }) => {
  const { putTimezone } = api.value.auth

  return putTimezone(data)
}

export const TimezoneSetting = () => {
  const profileStore = useSignal(profile)
  const { timezone } = { ...profileStore?.data }

  const modal = useModal()
  const { close: closeModal, open: openModal } = modal.modalControls

  const [selectedTimezone, setSelectedTimezone] = useState<Timezone>()

  const timezone_list = useSignal(timezones)

  const { loading, runAsync } = useRequest(request, { manual: true })

  const onCancel = () => setSelectedTimezone(undefined)

  const onConfirm = (timezone: string) => {
    toast
      .promise(runAsync({ timezone }), {
        loading: 'Updating..',
        error: errorMessageResolver,
        success: 'Timezone update successful.'
      })
      .then(() => {
        refreshState()
        closeModal()
      })
  }

  const state = (() => {
    if (!selectedTimezone) return 'choice'

    return selectedTimezone.key !== timezone ? 'confirm' : 'choice'
  })()

  return (
    <>
      <BaseModal {...modal}>
        <BaseModalContent className={modalContent}>
          {state == 'choice' && (
            <Choice
              currentTimezone={timezone}
              timezones={timezone_list}
              onChangeTimezone={setSelectedTimezone}
            />
          )}

          {state == 'confirm' && (
            <Confirm
              clearSelectedTimezone={onCancel}
              selectedTimezone={selectedTimezone}
              onCancel={onCancel}
              onConfirm={onConfirm}
              loading={loading}
            />
          )}
        </BaseModalContent>
      </BaseModal>

      <article className={container}>
        <BaseText
          label="Preferred Timezone"
          required
          affix={{ post: <Icon namespace="Down" className={iconStyle} /> }}
          fullWidth
          elementControlProps={{ style: { cursor: 'pointer' } }}
          inputProps={{
            readOnly: true,
            style: { pointerEvents: 'none' },
            onFocus: openModal,
            value: timezone_list.find(t => t.key === timezone)?.value ?? ''
          }}
        />
      </article>
    </>
  )
}

export default TimezoneSetting
