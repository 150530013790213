import { FC } from 'react'

import { propsWithClassNames } from '@genie-fintech/ui/functions'
import { status, StatusVariants } from '@genie-fintech/ui/style/element'
import { TagsNoRef } from '@genie-fintech/ui/types'

type Props = TagsNoRef<'span'> & {
  is_verified?: boolean
  verifiedText?: string
  unVerifiedText?: string
}

const VerifiedBadge: FC<Props> = ({
  is_verified,
  verifiedText = 'Verified',
  unVerifiedText = 'Unverified',
  ...props
}) => {
  const kind: StatusVariants['kind'] = (() => {
    if (is_verified) return 'success'
    return 'warning'
  })()

  const emailState = is_verified ? verifiedText : unVerifiedText

  return (
    <span
      {...propsWithClassNames(
        props,
        status({ kind, type: 'faint', size: 'tiny' })
      )}
    >
      {emailState.toLocaleUpperCase()}
    </span>
  )
}

export default VerifiedBadge
