import {
  apply2faGuardErrorResolver,
  parse2faGuardStagedContentResolver
} from '$services/api/resolvers'

import useMFABlock from './useMFABlock'

type Use2FAHelperOptions = {
  getMfaBlock: () => ReturnType<typeof useMFABlock>
}

export const use2FAHelper = ({ getMfaBlock }: Use2FAHelperOptions) => {
  const getFactorsByError = (error: unknown) => {
    const { available_factors = [] } = {
      ...apply2faGuardErrorResolver(error)?.data
    }

    return available_factors
  }

  const getFactorsByContent = <T>(content: T) => {
    const { available_factors = [] } = {
      ...parse2faGuardStagedContentResolver(content)
    }

    return available_factors
  }

  const is2FAContent = <T>(content: T) => {
    const data = parse2faGuardStagedContentResolver(content)

    return !!data
  }

  const is2FAError = (error: unknown) => {
    const data = apply2faGuardErrorResolver(error)?.data

    return data?.status === '2fa_required'
  }

  const perform2FAActionByContent = <T>(
    content: T,
    action: (
      data: Exclude<
        ReturnType<typeof parse2faGuardStagedContentResolver<T>>,
        undefined
      >
    ) => void
  ) => {
    const data = parse2faGuardStagedContentResolver(content)
    const is2FA = !!data && is2FAContent(data)

    if (is2FA) action(data)

    return is2FA
  }

  /**
   * Useful for handling 2FA-protected endpoint error.
   *
   * Ususally this is due to two reasons:
   * - mfa_token failure. example: expired
   * - invalid payload. example: form data
   */
  const reChallengeOrExitOnError = (error: unknown) => {
    const { mfa_token_status } = {
      ...apply2faGuardErrorResolver(error)?.data
    }
    if (mfa_token_status) {
      getMfaBlock().retakeSameChallenge()
    }
    // Only reset the challenge
    // Cuz mfa_token is still valid despite invalid payload.
    else {
      getMfaBlock().exit(false).finally(getMfaBlock().resetChallenge)
    }
  }

  return {
    reChallengeOrExitOnError,
    getFactorsByContent,
    getFactorsByError,
    is2FAContent,
    is2FAError,
    perform2FAActionByContent
  }
}

export default use2FAHelper
