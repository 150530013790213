import { FC, PropsWithChildren } from 'react'

import BaseModal, { BaseModalProps } from '$elements/BaseModal'

import Content, { ContentProps } from './Content'

export type ModalWithHeaderProps = ContentProps & {
  modalProps: BaseModalProps
}

export const ModalWithHeader: FC<PropsWithChildren<ModalWithHeaderProps>> = ({
  modalProps,
  ...contentProps
}) => (
  <BaseModal {...modalProps}>
    <Content {...contentProps} />
  </BaseModal>
)

export default ModalWithHeader
