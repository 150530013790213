import { FC, useEffect } from 'react'

import usePageClass from '$browser/usePageClass'
import useRouter from '$actions/useRouter'

import PageHead from '$layouts/AuthenticatedLayout/PageHead'
import PageRegion from '$layouts/AuthenticatedLayout/PageRegion'
import PageContents from '$layouts/AuthenticatedLayout/PageContents'

import TimezoneSetting from '$blocks/TimezoneSetting'
import ChangeEmailBlock from '$blocks/ChangeEmailBlock'
import ChangePhoneBlock from '$blocks/ChangePhoneBlock'

import { header as headerClassName } from './styles.css'
import ProfileBasicInfo from '$blocks/ProfileBasicInfo'

const pageName = 'ProfileDetail'

export const ProfileDetail: FC = () => {
  usePageClass({ name: pageName })

  const {
    queryParams: { open_change_password },
    redirectWithQueryParams
  } = useRouter()

  // Backward-compatibility for old web-auth sdk versions.
  useEffect(() => {
    if (!open_change_password) return

    redirectWithQueryParams('securityPassword')
  }, [open_change_password, redirectWithQueryParams])

  return (
    <>
      <PageHead
        title="User Details"
        description="Manage and review user accounts for efficient system administration."
      />

      <PageRegion>
        <ProfileBasicInfo />

        <PageContents
          title="Configure Timezone"
          description="The system will provide reports aligned with your selected timezone."
          headerClassName={headerClassName}
          nav={<TimezoneSetting />}
        />

        <PageContents
          title="Add/Edit Phone Number"
          description="You can update your phone number with anytime."
          headerClassName={headerClassName}
          nav={<ChangePhoneBlock />}
        />

        <PageContents
          title="Add/Edit Email Address"
          description="You can update your email with anytime."
          headerClassName={headerClassName}
          nav={<ChangeEmailBlock />}
        />
      </PageRegion>
    </>
  )
}

export default ProfileDetail
