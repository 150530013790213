import { RouteNames } from '$app/router'
import { authMethods, AuthMethods } from './auth'

export const VITE_DEPLOYMENT_TAG = import.meta.env.VITE_DEPLOYMENT_TAG

export const CF_TURNSTILE_HEADER_KEY = 'X-CF-Turnstile-Response'
export const CF_TURNSTILE_KEY = import.meta.env.VITE_CF_TURNSTILE_SITEKEY
export const CF_TURNSTILE_ENABLED = !!CF_TURNSTILE_KEY

export const NAMESPACE = 'genie-auth-webview'

export const LOCAL_STORAGE_KEY_SEPARATOR = ':'

export const THEME_NAME = import.meta.env.VITE_THEME_NAME

export const DEFAULT_ROUTE: RouteNames = 'applications'

export const DEFAULT_AUTH_METHOD: AuthMethods = authMethods[0]

export const GENERAL_TIME_FORMAT_WITH_TIMEZONE = 'dd MMM yyyy, hh:mma z'

export const DEFAULT_OTP_CODE_LENGTH = 6

export const DEFAULT_OTP_RETRY_TIMER = 1000 * 60

export const GENDER_OPTIONS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' }
]
