import { FC, PropsWithChildren, ReactNode } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'
import { status } from '@genie-fintech/ui/style/element'

import {
  container,
  info,
  actions,
  infoTitle,
  infoDescription,
  infoTitleWrapper,
  infoInner,
  infoIconWrapper
} from './styles.css'

type MethodProps = {
  icon: ReactNode
  title: ReactNode
  description?: ReactNode
  isPreferred?: boolean
} & TagsNoRef<'article'>

export const Method: FC<PropsWithChildren<MethodProps>> = ({
  title,
  icon,
  description,
  isPreferred,
  children,
  ...detailProps
}) => {
  return (
    <article {...propsWithClassNames(detailProps, container)}>
      <div className={info} onClick={e => e.preventDefault()}>
        <div className={infoIconWrapper}>{icon}</div>

        <div className={infoInner}>
          <div className={infoTitleWrapper}>
            <h5 className={infoTitle}>{title}</h5>

            {isPreferred && (
              <span
                className={status({
                  size: 'small',
                  type: 'faint',
                  kind: 'primary'
                })}
              >
                Default Method
              </span>
            )}
          </div>

          {description && <p className={infoDescription}>{description}</p>}
        </div>
      </div>

      {children && <div className={actions} children={children} />}
    </article>
  )
}

export default Method
