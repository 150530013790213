import { ReactNode } from 'react'

import { Icon } from '@genie-fintech/ui/icons'

import ExtraIcon from '$icons/ExtraIcon'

import { RouteNames, getDetail } from '$app/router'
import useSignal from '$actions/useSignal'
import { profile } from '$store/profile'

type GrouppedMenuLinks = {
  title?: string
  links: {
    name?: string
    routeName: RouteNames
    icon: ReactNode
  }[]
}[]

const MENU_LINKS: GrouppedMenuLinks = [
  {
    links: [
      {
        name: 'Access Applications',
        routeName: 'applications',
        icon: <ExtraIcon name="layer" />
      },
      {
        routeName: 'profileDetail',
        icon: <Icon namespace="User" />
      }
    ]
  },

  {
    title: 'Security',
    links: [
      {
        name: 'Devices Login',
        routeName: 'securityLoginHistory',
        icon: <ExtraIcon name="laptop-2" />
      },
      {
        routeName: 'securityMfa',
        icon: <ExtraIcon name="shield" />
      },
      {
        routeName: 'securityPassword',
        icon: <Icon namespace="Lock" />
      }
    ]
  }
]

export const useGetMenuLinks = () => {
  const profileData = useSignal(profile)

  const hasPassword = !!profileData?.data?.email

  return MENU_LINKS.map(({ links, ...rest }) => ({
    ...rest,
    links: links
      .filter(({ routeName }) => {
        if (routeName == 'securityPassword' && !hasPassword) return false

        return true
      })
      .map(({ routeName, name, ...rest }) => ({
        routeName,
        name: name || getDetail(routeName).meta?.title,
        ...rest
      }))
  }))
}

export default useGetMenuLinks
