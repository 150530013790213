import { Fragment } from 'react'
import { Icon } from '@genie-fintech/ui/icons'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

import BaseModal, { BaseModalContent } from '$elements/BaseModal'
import OTPBlock from '$blocks/OTPBlock'

import { useAuthenticatedLayout } from '../context'

import {
  container,
  description as descriptionStyle,
  header,
  title as titleStyle,
  actions,
  backButton,
  modalContent
} from './styles.css'

const SidebarActionCard = () => {
  const {
    sidebarActions: { formattedActions, hasMultipleActions, rotateAction }
  } = useAuthenticatedLayout()

  return (
    <>
      {formattedActions.map(
        ({ id, description, isActive, loading, onClick, title, props }) => (
          <Fragment key={id}>
            <article className={container} hidden={trueOrUndefined(!isActive)}>
              <div className={header}>
                <h2 className={titleStyle}>{title}</h2>

                <p className={descriptionStyle}>{description}</p>
              </div>

              <div className={actions}>
                <Button onClick={onClick} disabled={loading}>
                  {loading && <Spinner />}
                  Verify
                </Button>

                {hasMultipleActions && (
                  <Button
                    onClick={rotateAction}
                    disabled={loading}
                    title="Next action"
                    styleVariants={{
                      kind: 'warning',
                      category: 'icon',
                      size: 'small',
                      type: 'text'
                    }}
                  >
                    <Icon namespace="Recycling" />
                  </Button>
                )}
              </div>
            </article>

            {id === 'phone' && (
              <BaseModal
                {...props.modal}
                modalOptions={{ ...props.modal.modalOptions, title }}
              >
                <BaseModalContent className={modalContent}>
                  <button
                    type="button"
                    onClick={props.cancel}
                    className={backButton}
                    disabled={loading}
                  >
                    <Icon namespace="Backward" /> Back
                  </button>

                  <OTPBlock {...props.otpBlock} />
                </BaseModalContent>
              </BaseModal>
            )}
          </Fragment>
        )
      )}
    </>
  )
}

export default SidebarActionCard
