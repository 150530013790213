import {
  batch,
  computed,
  effect,
  signal,
  untracked
} from '@preact/signals-core'
import { Required } from 'utility-types'

import { sources } from '$model/api'
import { Profile } from '$api/types'

import session from './session'
import { all_ids } from './session/tokens'

type ProfileState = Pick<Profile, 'id'> & {
  loading: boolean

  data?: Profile
}

type NewState = Partial<Omit<ProfileState, 'id'>>

const applyDefaultStateValue = (
  states: Required<Partial<ProfileState>, 'id'>
) => ({
  loading: false,
  ...states
})

const states = signal<ProfileState[]>([])

export const with_tokens = computed(() =>
  states.value.map(({ id, ...rest }) => ({
    ...rest,
    id,
    token: session.value.tokenDetails.find(d => d.id === id)?.token
  }))
)

export const profiles = computed(() => ({
  loadingAny: states.value.some(({ loading }) => loading),
  data: states.value.map(({ data, ...rest }) => ({
    ...rest,
    data,
    isEmpty: !!data
  }))
}))

const getState = (id: ProfileState['id']) => {
  return states.value.find(state => state.id === id)
}

const excludeStates = (id: ProfileState['id']) => {
  return states.value.filter(state => state.id !== id)
}

const removeState = (id: ProfileState['id']) => {
  states.value = excludeStates(id)
}

const addOrUpdateState = (id: ProfileState['id'], newState: NewState) => {
  const statesValue = states.value

  const state = statesValue.find(s => s.id === id)

  // Update
  if (state) {
    states.value = statesValue.map(state => ({
      ...state,
      ...(state.id === id && newState)
    }))

    return
  }

  // Add
  states.value = [
    ...statesValue,
    applyDefaultStateValue({
      id,
      ...newState
    })
  ]
}

export const refreshState = async (id: ProfileState['id']) => {
  const source = sources.value.find(source => source.id === id)

  if (!source) return

  const { auth: api } = source

  const state = getState(id)

  // Skip when in-progress
  if (state?.loading) return

  try {
    addOrUpdateState(id, { loading: true })

    const { data } = await api.profile().then(api.successResolver)

    addOrUpdateState(id, { loading: false, data })
  } catch {
    addOrUpdateState(id, { loading: false, data: undefined })
  }
}

export const refreshStates = () => {
  return Promise.all(sources.value.map(({ id }) => refreshState(id)))
}

/**
 * Type: Effect
 * Refresh `states`
 */
sources.subscribe(refreshStates)

/**
 * Type: Effect
 * Remove old states
 */
effect(() => {
  const idValues = all_ids.value
  const stateValues = states.value

  const outdatedIDs = stateValues
    .map(({ id }) => id)
    .filter(id => !idValues.includes(id))

  untracked(() => {
    batch(() => outdatedIDs.map(removeState))
  })
})
