import { FC, PropsWithChildren } from 'react'

import { propsWithClassNames } from '@genie-fintech/ui/functions'

import BaseModal, { BaseModalProps } from '$elements/BaseModal'

import Content, { ContentProps } from './Content'
import { overlay } from './styles.css'

export type FocusModalProps = ContentProps & {
  modalProps: BaseModalProps
}

export const FocusModal: FC<PropsWithChildren<FocusModalProps>> = ({
  modalProps,
  ...contentProps
}) => (
  <BaseModal
    {...propsWithClassNames(modalProps, overlay)}
    onOverlayClick={() => undefined}
    disabledOverlayClick={true}
  >
    <Content {...contentProps} />
  </BaseModal>
)

export default FocusModal
