import { computed } from '@preact/signals-core'

import { current } from '$store/session'
import { details } from '$store/session/tokens'

import { Token } from '$api/common'
import { AuthAPI } from '$api/auth'
import { MfaAuthAPI } from '$api/mfa-auth'
import { omit } from 'lodash-es'

// Add multiple API sources here
const createSources = (token: Token) => ({
  auth: new AuthAPI(token),
  mfaAuth: new MfaAuthAPI(token)
})

/**
 * Type: Readonly
 */
export const sources = computed(() =>
  details.value.map(({ token, ...rest }) => ({
    ...rest,
    ...createSources(token)
  }))
)

/**
 * State: Computed
 */
export const activeSource = computed(() =>
  sources.value.find(({ id }) => id === current.value)
)
export const hasActiveSource = computed(() => !!activeSource.value)

/**
 * State: Computed
 */
export const api = computed(() => {
  const defaultSource = createSources(null)

  return omit(activeSource.value || defaultSource, 'id')
})

export type Sources = (typeof api)['value']

export default api
