import { useController } from 'react-hook-form'

import { FieldContainer } from '@genie-fintech/ui/components/fields'
import { resolveErrorMessage } from '@genie-fintech/ui/components/hook-fields/common/functions'
import { HookField } from '@genie-fintech/ui/components/hook-fields/common/types'

import { CF_TURNSTILE_ENABLED } from '$constants'

import useCFTurnstile from '$actions/useCFTurnstile'
import { useEffect } from 'react'

export const CFTurnstile: HookField = ({
  name,
  control,
  disabled: explictDisabled,
  controllerOptions
}) => {
  const disabled = explictDisabled || !CF_TURNSTILE_ENABLED

  const { field, fieldState } = useController({
    name,
    control,
    disabled,
    ...controllerOptions
  })

  const { onChange } = field
  const { isDirty } = fieldState

  const errorMessage = resolveErrorMessage(fieldState.error)
  const hasError = !!errorMessage

  const { targetRef, reset } = useCFTurnstile<HTMLDivElement>({
    disabled,
    onSuccess: token => onChange(token),
    onError: () => onChange(''),
    onReset: () => onChange('')
  })

  useEffect(() => {
    if (isDirty) return

    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  if (disabled) return <></>

  return (
    <FieldContainer disabled={disabled} error={hasError} message={errorMessage}>
      {() => <div ref={targetRef} />}
    </FieldContainer>
  )
}

export default CFTurnstile
