import { FC } from 'react'

import { Button } from '@genie-fintech/ui/components'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

import useOTPBlock from '$actions/useOTPBlock'

import {
  description as descriptionStyle,
  header,
  title as titleStyle
} from '$blocks/common/auth.css'
import OTPInput from '$elements/OTPInput'

import { otp } from './styles.css'

export type OTPBlockProps = ReturnType<typeof useOTPBlock>

export const OTPBlock: FC<OTPBlockProps> = ({
  otpInput,
  refCode,
  onResend,
  timerActive,
  timerSeconds,
  title,
  description
}) => (
  <>
    <header className={header}>
      <h2 className={titleStyle}>{title}</h2>

      <p className={descriptionStyle}>
        {description}
        {refCode && (
          <>
            Ref: (<span className={otp.highlightedText}>{refCode}</span>)
          </>
        )}
      </p>
    </header>

    <main className={otp.main}>
      <OTPInput autoFocus {...propsWithClassNames(otpInput, otp.field)} />

      <div>
        {timerActive ? (
          <p className={otp.reSendText}>
            Resend in{' '}
            <span className={otp.highlightedText}>{timerSeconds}s</span>
          </p>
        ) : (
          <Button
            styleVariants={{ size: 'small' }}
            onClick={onResend}
            disabled={otpInput.disabled}
          >
            Resend OTP
          </Button>
        )}
      </div>
    </main>
  </>
)

export default OTPBlock
