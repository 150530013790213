import Axios, {
  AxiosResponse,
  CreateAxiosDefaults,
  AxiosInstance,
  isAxiosError
} from 'axios'
import { isEmpty } from 'lodash-es'

import { VITE_DEPLOYMENT_TAG } from '$constants'
import { filterValues } from '$app/utilities'

import {
  InterceptorErrorResponse,
  ErrorResponseWatcher,
  ResponseInterceptor,
  RequestInterceptor
} from './types'

export type Token = string | null

export const toAuthorization = (token: string) => `Bearer ${token}`

export const successResolver = <T extends AxiosResponse>({
  data
}: T): T['data'] => {
  return data
}

export const errorResolver = <T>(error: unknown) => {
  if (isAxiosError<T>(error)) {
    return error.response?.data
  }
}

export const errorMessageResolver = (error: unknown, defaultMessage = '') => {
  if (isAxiosError(error)) {
    return `${error.response?.data?.message || error.response?.statusText || defaultMessage}`
  }

  return defaultMessage
}

export const errorStatusResolver = (error: unknown) => {
  if (isAxiosError(error)) return error.response?.status || error.status
}

export class CommonAPI {
  public api: AxiosInstance

  protected apiResponse: ResponseInterceptor

  protected apiRequest: RequestInterceptor

  protected errorResponseWatchers: ErrorResponseWatcher[] = []

  constructor(defaults?: CreateAxiosDefaults) {
    this.api = Axios.create({
      ...defaults,
      headers: {
        Accept: 'application/json',
        ...(defaults?.headers as object)
      }
    })

    if (VITE_DEPLOYMENT_TAG) {
      this.api.defaults.headers.common['x-deployment-tag'] = VITE_DEPLOYMENT_TAG
    }

    this.apiRequest = this.api.interceptors.request
    this.apiResponse = this.api.interceptors.response
  }

  /**
   * Filter out falsy values from payload.
   */
  public filterRequestPayload = () => {
    const process = this.apiRequest.use(req => {
      if (req.data) req.data = filterValues(req.data)
      if (req.params) req.params = filterValues(req.params)

      return req
    })

    return () => this.apiRequest.eject(process)
  }

  public watchErrorResponse = (
    callback: (err: InterceptorErrorResponse) => void
  ) => {
    const process = this.apiResponse.use(
      response => response,
      error => {
        callback(error)

        throw error
      }
    )

    this.errorResponseWatchers.push(process)

    return () => this.apiResponse.eject(process)
  }

  public destroyErrorResponseWatchers = () => {
    const eject = this.apiResponse.eject

    this.errorResponseWatchers.map(eject)

    this.errorResponseWatchers = []
  }

  public updateToken = (token: Token) => {
    if (!token) {
      delete this.api.defaults.headers.common['Authorization']
    } else {
      this.api.defaults.headers.common['Authorization'] = toAuthorization(token)
    }
  }

  public hasToken = () => {
    return !isEmpty(this.api.defaults.headers.common['Authorization'])
  }

  public successResolver = successResolver

  public errorMessageResolver = errorMessageResolver

  public errorStatusResolver = errorStatusResolver
}
