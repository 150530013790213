import { FC, PropsWithChildren } from 'react'
import FocusLock from 'react-focus-lock'

import { Portal } from '@genie-fintech/ui/components'
import { PropsWithClassName } from '@genie-fintech/ui/types'
import {
  propsWithClassNames,
  toClassNames,
  trueOrUndefined
} from '@genie-fintech/ui/functions'

import { Provider, UseModalContextReturns } from '$contexts/Modal'

import { overlay, overlayContent } from './styles.css'

export type BaseModalProps = PropsWithClassName & UseModalContextReturns

export const BaseModal: FC<PropsWithChildren<BaseModalProps>> = ({
  children,
  className,
  ...props
}) => {
  const { modalStatus: open, onOverlayClick, disabledOverlayClick } = props

  return (
    <Portal>
      <Provider value={props}>
        <FocusLock disabled={!open}>
          <dialog
            open={trueOrUndefined(open)}
            data-overlay-click-disabled={trueOrUndefined(disabledOverlayClick)}
            className={toClassNames(overlay({ open }), className)}
            onClick={onOverlayClick}
            children={children}
          />
        </FocusLock>
      </Provider>
    </Portal>
  )
}

export type BaseModalContentProps = PropsWithClassName

export const BaseModalContent: FC<
  PropsWithChildren<BaseModalContentProps>
> = props => <article {...propsWithClassNames(props, overlayContent)} />

export default BaseModal
