import useMFAInfo from '$actions/useMFAInfo'

import { useModal } from '$contexts/Modal'

import useRemove, { UseRemoveOptions } from './useRemove'
import useSetup, { UseSetupOptions } from './useSetup'

export type UseConfigureTOTPOptions = {
  mfaInfo: ReturnType<typeof useMFAInfo>
  setupOptions: UseSetupOptions
  removeOptions: UseRemoveOptions
  disabled?: boolean
}

export type UseConfigureTOTPReturn = ReturnType<typeof useConfigureTOTP>

export const useConfigureTOTP = ({
  mfaInfo,
  setupOptions,
  removeOptions,
  disabled: forcedDisabled = false
}: UseConfigureTOTPOptions) => {
  const hasTotp = mfaInfo.registered_factors.find(({ type }) => type === 'totp')

  const setup = useSetup({
    ...setupOptions,
    onUpdated: () => {
      setupOptions.onUpdated?.()
      mfaInfo.refresh()
    }
  })

  const remove = useRemove({
    ...removeOptions,
    onRemoveed: () => {
      removeOptions.onRemoveed?.()
      mfaInfo.refresh()
    }
  })

  const mainModal = useModal({
    title: 'Mfa Authenticator App Verification',
    onClose: () => {
      setup.modal.modalControls.close()
      remove.modal.modalControls.close()
    }
  })

  const disabled =
    forcedDisabled ||
    mfaInfo.isLoading ||
    setup.isAnyLoading ||
    remove.isLoading

  return {
    hasTotp,
    mainModal,
    setup,
    disabled,
    remove
  }
}

export default useConfigureTOTP
