import { FC } from 'react'
import { format, parseISO } from 'date-fns'

import { GENERAL_TIME_FORMAT_WITH_TIMEZONE } from '$constants'
import { serviceName } from '$constants/texts'

import useSignal from '$actions/useSignal'
import { profile } from '$store/profile'

import { useAuthenticatedLayout } from '../context'
import AccountInfo from '../AccountInfo'
import Menu from '../Menu'
import SidebarActionCard from '../SidebarActionCard'

import {
  container,
  info,
  about,
  menus,
  footer,
  aboutTitle,
  aboutDescription,
  footerTitle,
  footerDescription,
  actions,
  actionsContent
} from './styles.css'

export const SidebarContents: FC = () => {
  const profileStore = useSignal(profile)
  const { created_at } = { ...profileStore?.data }

  const {
    sidebarActions: { hasActions }
  } = useAuthenticatedLayout()

  const joinedTime = (() => {
    if (!created_at) return

    return format(parseISO(created_at), GENERAL_TIME_FORMAT_WITH_TIMEZONE)
  })()

  return (
    <div className={container}>
      <div className={info}>
        <AccountInfo />
      </div>

      <div className={about}>
        <h4 className={aboutTitle}>Profile Setting</h4>
        <p className={aboutDescription}>
          Your setting can be editable on {serviceName}.
        </p>
      </div>

      <div className={menus}>
        <Menu />
      </div>

      {hasActions && (
        <div className={actions}>
          <div className={actionsContent}>
            <SidebarActionCard />
          </div>
        </div>
      )}

      {joinedTime && (
        <footer className={footer}>
          <h5 className={footerTitle}>Joined ON</h5>
          <p className={footerDescription}>{joinedTime}</p>
        </footer>
      )}
    </div>
  )
}

export default SidebarContents
