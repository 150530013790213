import { useEffect } from 'react'
import { useBoolean, useRequest } from 'ahooks'
import toast from 'react-hot-toast'

import { useIsMounted } from '@genie-fintech/ui/hooks'
import { Button } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'

import { DEFAULT_OTP_CODE_LENGTH, DEFAULT_OTP_RETRY_TIMER } from '$constants'

import { useModal } from '$contexts/Modal'

import useTimer from '$actions/useTimer'
import useOTPInput from '$actions/useOTPInput'
import useOTPBlock from '$actions/useOTPBlock'
import useMFABlock from '$actions/useMFABlock'
import useSignal from '$actions/useSignal'
import use2FAHelper from '$actions/use2FAHelper'
import useRouter from '$actions/useRouter'

import { profile, refreshState } from '$store/profile'
import api from '$model/api'

import { ToastMessage } from '$app/types'

import { errorMessageResolver } from '$api/common'

import MFABlock from '$blocks/MFABlock'
import BaseModal, { BaseModalContent } from '$elements/BaseModal'
import VerifiedBadge from '$elements/VerifiedBadge'
import ChangeEmailRequestForm, {
  ChangeEmailRequestFormProps
} from '$hook-forms/ChangeEmailRequestForm'

import { description, header, title } from '$blocks/common/auth.css'

import OTPBlock from '../OTPBlock'

import { backButton, container, content, value } from './styles.css'

const otpRequestToastMsg: ToastMessage = {
  loading: 'Requesting..',
  error: errorMessageResolver,
  success: 'OTP requested.'
}

export const ChangeEmailBlock = () => {
  const {
    queryParams: { block_action }
  } = useRouter()
  const isMounted = useIsMounted()

  const otpTimer = useTimer(DEFAULT_OTP_RETRY_TIMER)

  const [isConfirmState, { setTrue: setConfirm, setFalse: cancelConfirm }] =
    useBoolean()

  const {
    auth: { changeEmailRequest, changeEmailConfirm },
    mfaAuth: apiInstance
  } = useSignal(api)

  const profileStore = useSignal(profile)
  const { is_email_verified, email } = { ...profileStore?.data }

  const emailModal = useModal({
    onClose: () => {
      reset()
      mfaBlock.exit()
    }
  })

  const {
    toggle: toggleEmailModal,
    close: closeEmailModal,
    open: openEmailModal
  } = emailModal.modalControls

  const changeEmailReq = useRequest(changeEmailRequest, {
    manual: true,
    onError: err => {
      if (twoFAHelper.is2FAError(err)) {
        mfaBlock.modalControls.open()
      }
    },
    onFinally: () => {
      otpInput.resetPasswords()
      otpTimer.reset()
    },
    onSuccess: () => {
      mfaBlock.exit()
      setConfirm()
    }
  })

  const confirmEmailReq = useRequest(changeEmailConfirm, {
    manual: true,
    onSuccess: () => {
      refreshState()
      closeEmailModal()
    },
    onFinally: () => {
      otpInput.resetPasswords()
    }
  })

  const requests = [changeEmailReq, confirmEmailReq]

  const otpInput = useOTPInput({
    length: DEFAULT_OTP_CODE_LENGTH,
    disabled: confirmEmailReq.loading,
    onFilled: code => {
      const { runAsync, cancel } = confirmEmailReq

      cancel()

      toast.promise(runAsync({ code }), {
        error: errorMessageResolver,
        loading: 'Verifying..',
        success: 'Email updated successfully!'
      })
    }
  })

  const otpBlock = useOTPBlock({
    otpInput,
    title: 'Enter Code',
    description: `We’ve sent ${DEFAULT_OTP_CODE_LENGTH} digit verification code to new email.`,
    timerActive: otpTimer.active,
    timerSeconds: otpTimer.seconds,
    onResend: () => {
      const { refreshAsync, cancel } = changeEmailReq

      cancel()

      toast.promise(refreshAsync(), {
        error: errorMessageResolver,
        loading: 'Resending..',
        success: 'OTP requested'
      })
    }
  })

  const twoFAHelper = use2FAHelper({ getMfaBlock: () => mfaBlock })
  const available_factors = twoFAHelper.getFactorsByError(changeEmailReq.error)

  const mfaBlock = useMFABlock({
    apiInstance,
    data: { available_factors },
    onVerified: mfa_token => {
      const { params, runAsync } = changeEmailReq

      // Make sure there was a previous call
      if (!params.length) return

      const [data] = params

      // Auto retry the request with token
      toast
        .promise(runAsync({ ...data, mfa_token }), otpRequestToastMsg)
        .catch(twoFAHelper.reChallengeOrExitOnError)
    }
  })

  const { mfa_token } = mfaBlock

  const reset = () => {
    requests.forEach(({ cancel, mutate }) => {
      cancel()
      mutate()
    })
    cancelConfirm()
  }

  const onContinue: ChangeEmailRequestFormProps['onContinue'] = data => {
    const { runAsync } = changeEmailReq

    toast.promise(runAsync({ ...data, mfa_token }), otpRequestToastMsg)
  }

  // Auto-open modal
  useEffect(() => {
    if (!isMounted) return

    if (block_action !== 'ChangeEmailBlock') return

    openEmailModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block_action, isMounted])

  return (
    <>
      <article className={container}>
        {!!email && <VerifiedBadge is_verified={is_email_verified} />}

        <span className={value}>{email}</span>

        <Button
          styleVariants={{ kind: 'neutral', size: 'small', type: 'outlined' }}
          onClick={toggleEmailModal}
        >
          {!email && <Icon namespace="Add" />}
          {email ? 'Change' : 'Add Email'}
        </Button>
      </article>

      <BaseModal {...emailModal}>
        <BaseModalContent className={content}>
          {isConfirmState && (
            <>
              <button
                type="button"
                onClick={reset}
                className={backButton}
                disabled={confirmEmailReq.loading}
              >
                <Icon namespace="Backward" /> Back
              </button>

              <OTPBlock {...otpBlock} />
            </>
          )}

          {!isConfirmState && (
            <>
              <header className={header}>
                <p className={title}>
                  {email ? 'Enter New Email' : 'Add Email'}
                </p>
                <p className={description}>
                  {email
                    ? 'Enter new email to continue the changes.'
                    : 'Enter email to continue the process.'}
                </p>
              </header>

              <ChangeEmailRequestForm
                email={email}
                loading={changeEmailReq.loading}
                onCancel={closeEmailModal}
                onContinue={onContinue}
              />
            </>
          )}
        </BaseModalContent>
      </BaseModal>

      <MFABlock {...mfaBlock} />
    </>
  )
}

export default ChangeEmailBlock
