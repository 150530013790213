import { useRequest } from 'ahooks'
import toast from 'react-hot-toast'

import useSignal from '$actions/useSignal'
import api from '$model/api'
import { errorMessageResolver } from '$services/api/common'

export const useEmailVerifyRequest = () => {
  const {
    auth: { emailVerificationRequest }
  } = useSignal(api)
  const { runAsync, cancel, loading } = useRequest(emailVerificationRequest, {
    manual: true
  })

  const request = async () => {
    cancel()

    await toast.promise(runAsync(), {
      error: errorMessageResolver,
      loading: 'Requesting verify email..',
      success: 'Requested verify email!'
    })
  }

  return { loading, request }
}

export default useEmailVerifyRequest
