import { FC, useRef } from 'react'
import { useRequest } from 'ahooks'
import toast from 'react-hot-toast'

import useRouter from '$actions/useRouter'
import useMFABlock from '$actions/useMFABlock'

import Link from '$elements/Link'

import {
  suggestedLink,
  suggestedLinkContainer,
  suggestedLinkLabel
} from '$blocks/common/auth.css'
import MFABlock from '$blocks/MFABlock'

import { ToastMessage } from '$app/types'
import { asyncSuccessResolver } from '$app/utilities'

import { AnonymousAuthAPI } from '$services/api/anonymous-auth'
import { MfaAuthAPI } from '$services/api/mfa-auth'
import {
  apply2faGuardErrorResolver,
  parse2faGuardStagedContentResolver
} from '$services/api/resolvers'
import { successResolver, errorMessageResolver } from '$services/api/common'

import ForgotPasswordForm, {
  ForgotPasswordFormProps
} from '$hook-forms/ForgotPasswordForm'

import {
  container,
  description,
  header,
  loginLinkLabel,
  title
} from './styles.css'

const toastMsg = {
  loading: 'Requesting..',
  error: errorMessageResolver,
  success: 'Reset link sent! Check your email to continue.'
} satisfies ToastMessage

export const ForgotPassword: FC = () => {
  const { redirectWithQueryParams } = useRouter()

  const { current: anonymousApi } = useRef(new AnonymousAuthAPI(null))
  const { current: mfaApi } = useRef(new MfaAuthAPI(null))

  const setAuthToken: typeof anonymousApi.updateToken = token => {
    anonymousApi.updateToken(token)
    mfaApi.updateToken(token)
  }

  const { forgotPassword, forgotPassword2FA } = anonymousApi

  const forgotPasswordRequest = useRequest(
    asyncSuccessResolver(forgotPassword, successResolver),
    { manual: true }
  )
  const forgotPassword2FARequest = useRequest(
    asyncSuccessResolver(forgotPassword2FA, successResolver),
    {
      manual: true,
      onSuccess: () => {
        onRequested()
      }
    }
  )

  const loading =
    forgotPasswordRequest.loading || forgotPassword2FARequest.loading

  const { available_factors = [] } = {
    ...parse2faGuardStagedContentResolver(forgotPasswordRequest.data?.data)
  }

  const mfaBlock = useMFABlock({
    apiInstance: mfaApi,
    data: { available_factors },
    onVerified: mfa_token => {
      const { runAsync } = forgotPassword2FARequest

      toast
        .promise(runAsync({ mfa_token }), toastMsg)
        .then(onRequested)
        // If this fails, ususally this is due to two reasons
        // - mfa_token failure. example: expired
        // - invalid payload. example: form data
        .catch(error => {
          const { mfa_token_status } = {
            ...apply2faGuardErrorResolver(error)?.data
          }
          if (mfa_token_status) {
            mfaBlock.retakeSameChallenge()
          }
          // Only reset the challenge
          // Cuz mfa_token is still valid despite invalid payload.
          else {
            mfaBlock.exit(false).finally(mfaBlock.resetChallenge)
          }
        })
    }
  })

  const onRequested = () => {
    // Retain ?return_to query
    redirectWithQueryParams('authLogin')
  }

  const onSuccess: ForgotPasswordFormProps['onSuccess'] = async (
    reset,
    data
  ) => {
    const { runAsync } = forgotPasswordRequest

    const result = await toast.promise(
      runAsync({ ...data, request_origin: self.origin }),
      {
        ...toastMsg,
        success: ({ data, message }) => {
          const { auth_token } = {
            ...parse2faGuardStagedContentResolver(data)
          }

          return auth_token ? message : toastMsg.success
        }
      }
    )

    reset()

    if (!result) {
      onRequested()

      return
    }

    const { auth_token, status } = {
      ...parse2faGuardStagedContentResolver(result.data)
    }

    if (auth_token && status == '2fa_required') {
      setAuthToken(auth_token)
      mfaBlock.modalControls.open()
    }
  }

  return (
    <section className={container}>
      <header className={header}>
        <h2 className={title}>Get password reset link</h2>

        <p className={description}>
          We will send the reset password link to your email inbox.
        </p>
      </header>

      <ForgotPasswordForm onSuccess={onSuccess} disabled={loading} />
      <MFABlock {...mfaBlock} />

      <div className={suggestedLinkContainer}>
        <p className={suggestedLinkLabel}>OR</p>

        <p>
          <span className={loginLinkLabel}>Remember your password?</span>{' '}
          <Link
            to="authLogin"
            // Retain ?return_to query
            options={({ queryParams }) => ({ queryParams })}
            className={suggestedLink}
          >
            Click here to log in.
          </Link>
        </p>
      </div>
    </section>
  )
}

export default ForgotPassword
