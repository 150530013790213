import { computed, signal } from '@preact/signals-core'
import { merge } from 'lodash-es'
import { LiteralUnion } from 'type-fest'

import { RemoveRecord } from '$app/types'

import { AuthMethods, authMethods } from '$constants/auth'
import { allowedCountries, CountryCode } from '$constants/country-data'
import { DEFAULT_AUTH_METHOD } from '$constants'

import { all_ids } from '$store/session/tokens'

import { GetCodeRequest } from '$api/types'
import { ExtendedQueryParams, routeState } from '$app/router'

type Params = Partial<GetCodeRequest & ExtendedQueryParams>
type Keys = LiteralUnion<keyof RemoveRecord<Params>, string>

/**
 * Type: Store
 */
const params = signal<Params>(routeState.queryParams)

/**
 * Parse to allowed values
 */
const parsedParams = computed(() => {
  const values = params.value

  // Return `undefined` when invalid
  // Note: ID's token validity must be checked seperately before consuming
  const auth_user = (() => {
    const value = values.auth_user
    const storedUserIDs = all_ids.value

    const isValid = value && storedUserIDs.includes(value as never)

    if (!isValid) return

    return value
  })()

  // Return `undefined` when invalid
  const auth_country = (() => {
    const value = values.auth_country

    const isValid = value && allowedCountries.includes(value as never)

    if (!isValid) return

    return value as CountryCode
  })()

  // Return `default` when invalid
  const auth_method = (() => {
    const value = values.auth_method

    const isValid = value && authMethods.includes(value as never)

    if (!isValid) return DEFAULT_AUTH_METHOD

    return value as AuthMethods
  })()

  // Return `undefined` when invalid
  const auth_phone_number = (() => {
    const value = values.auth_phone_number

    if (!value) return

    return parseFloat(`${value}`) as number
  })()

  return {
    ...values,
    auth_user,
    auth_country,
    auth_method,
    auth_phone_number
  }
})

/**
 * Type: Store
 */
export const cached = signal(false)

/**
 * Cached OAuth query parameters from the initial request.
 *
 * Type: Read
 */
export const queryParams = computed(() => ({
  params: parsedParams.value,
  cached: cached.value
}))

export const update = (value: Params) => {
  params.value = { ...params.value, ...value }
}

export const clearQueryParamCache = (keys: Keys[]) => {
  const targets = keys.map(key => ({
    [key]: undefined
  }))

  // @ts-expect-error .map isn't very type-safe
  update(merge(...targets))
}

export const setCached = () => {
  cached.value = true
}

export default queryParams
