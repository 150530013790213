import { errorResolver } from './common'
import { With2FAGuardErrorResponse, With2FAGuardStagedResponse } from './types'

/**
 * Type: `content` resolver.
 *
 * Detects if the response is a 2FA guard requirement.
 *
 * Expects `payload` to be an object.
 * Usually resolved response from axios's `response.data`.
 *
 * Returns `undefined` if the response is not a 2FA guard requirement.
 */
export const parse2faGuardStagedContentResolver = <T>(
  payload?: With2FAGuardStagedResponse<T>['data']
) => {
  if (!payload) return

  if (
    typeof payload === 'object' &&
    'status' in payload &&
    payload.status == '2fa_required'
  ) {
    return payload satisfies Required<typeof payload>
  }
}

/**
 * Type: `content` resolver.
 *
 * Detects if the response is not a 2FA guard requirement.
 *
 * Expects `payload` to be an object.
 * Usually resolved response from axios's `response.data`.
 *
 * Returns `undefined` if the response is a 2FA guard requirement.
 */
export const parseNo2faGuardStagedContentResolver = <T>(
  payload?: With2FAGuardStagedResponse<T>['data']
) => {
  if (!payload) return

  const validPayload = typeof payload === 'object'
  const hasStatus = validPayload && 'status' in payload
  const is2FAStatus = hasStatus && payload.status == '2fa_required'

  if (is2FAStatus) return

  return payload as T
}

/**
 * Type: `response` resolver.
 */
export const apply2faGuardErrorResolver = (error: unknown) => {
  return errorResolver<With2FAGuardErrorResponse>(error)
}
