import { FC, useEffect } from 'react'
import { useBoolean, useRequest } from 'ahooks'
import toast from 'react-hot-toast'

import { useIsMounted } from '@genie-fintech/ui/hooks'
import { Button } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'

import { DEFAULT_OTP_CODE_LENGTH, DEFAULT_OTP_RETRY_TIMER } from '$constants'

import { description, header, title } from '$blocks/common/auth.css'

import MFABlock from '$blocks/MFABlock'
import BaseModal, { BaseModalContent } from '$elements/BaseModal'
import ChangePhoneRequestForm, {
  ChangePhoneRequestFormProps
} from '$hook-forms/ChangePhoneRequestForm'

import useTimer from '$actions/useTimer'
import useOTPInput from '$actions/useOTPInput'
import useOTPBlock from '$actions/useOTPBlock'

import { ToastMessage } from '$app/types'
import { errorMessageResolver } from '$api/common'

import useSignal from '$actions/useSignal'
import { useModal } from '$contexts/Modal'
import useMFABlock from '$actions/useMFABlock'
import use2FAHelper from '$actions/use2FAHelper'
import useRouter from '$actions/useRouter'

import api from '$model/api'
import { profile, refreshState } from '$store/profile'

import OTPBlock from '../OTPBlock'

import { backButton, container, content, value } from './styles.css'
import VerifiedBadge from '$elements/VerifiedBadge'
import { getPhone } from '$app/utilities'

const otpRequestToastMsg: ToastMessage = {
  loading: 'Requesting..',
  error: errorMessageResolver,
  success: 'OTP requested.'
}

export const ChangePhoneBlock: FC = () => {
  const {
    queryParams: { block_action }
  } = useRouter()
  const isMounted = useIsMounted()

  const otpTimer = useTimer(DEFAULT_OTP_RETRY_TIMER)

  const [isConfirmState, { setTrue: setConfirm, setFalse: cancelConfirm }] =
    useBoolean()

  const {
    auth: { changePhoneRequest, changePhoneConfirm },
    mfaAuth: apiInstance
  } = useSignal(api)

  const profileStore = useSignal(profile)
  const { phone_code, phone_no, is_phone_verified } = { ...profileStore?.data }

  const phoneModal = useModal({
    onClose: () => {
      reset()
      mfaBlock.exit()
    }
  })

  const {
    toggle: togglePhoneModal,
    close: closePhoneModal,
    open: openPhoneModal
  } = phoneModal.modalControls

  const changePhoneReq = useRequest(changePhoneRequest, {
    manual: true,
    onError: err => {
      if (twoFAHelper.is2FAError(err)) {
        mfaBlock.modalControls.open()
      }
    },
    onFinally: () => {
      otpInput.resetPasswords()
      otpTimer.reset()
    },
    onSuccess: () => {
      mfaBlock.exit()
      setConfirm()
    }
  })

  const confirmPhoneReq = useRequest(changePhoneConfirm, {
    manual: true,
    onSuccess: () => {
      refreshState()
      closePhoneModal()
    },
    onFinally: () => {
      otpInput.resetPasswords()
    }
  })

  const requests = [changePhoneReq, confirmPhoneReq]

  const twoFAHelper = use2FAHelper({ getMfaBlock: () => mfaBlock })
  const available_factors = twoFAHelper.getFactorsByError(changePhoneReq.error)

  const otpInput = useOTPInput({
    length: DEFAULT_OTP_CODE_LENGTH,
    disabled: confirmPhoneReq.loading,
    onFilled: code => {
      const { runAsync, cancel } = confirmPhoneReq

      cancel()

      toast.promise(runAsync({ code }), {
        error: errorMessageResolver,
        loading: 'Verifying..',
        success: 'Phone updated successfully!'
      })
    }
  })

  const otpBlock = useOTPBlock({
    otpInput,
    title: 'Enter Code',
    description: `We’ve sent ${DEFAULT_OTP_CODE_LENGTH} digit verification code to new phone number.`,
    timerActive: otpTimer.active,
    timerSeconds: otpTimer.seconds,
    onResend: () => {
      const { refreshAsync, cancel } = changePhoneReq

      cancel()

      toast.promise(refreshAsync(), {
        error: errorMessageResolver,
        loading: 'Resending..',
        success: 'OTP requested'
      })
    }
  })

  const mfaBlock = useMFABlock({
    apiInstance,
    data: { available_factors },
    onVerified: mfa_token => {
      const { params, runAsync } = changePhoneReq

      // Make sure there was a previous call
      if (!params.length) return

      const [data] = params

      // Auto retry the request with token
      toast
        .promise(runAsync({ ...data, mfa_token }), otpRequestToastMsg)
        .catch(twoFAHelper.reChallengeOrExitOnError)
    }
  })

  const phone = getPhone({ phone_code, phone_no })
  const hasPhone = !!phone

  const reset = () => {
    requests.forEach(({ cancel, mutate }) => {
      cancel()
      mutate()
    })
    cancelConfirm()
  }

  const onContinue: ChangePhoneRequestFormProps['onContinue'] = payload => {
    const { runAsync } = changePhoneReq

    toast.promise(runAsync(payload), otpRequestToastMsg)
  }

  // Auto-open modal
  useEffect(() => {
    if (!isMounted) return

    if (block_action !== 'ChangePhoneBlock') return

    openPhoneModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block_action, isMounted])

  return (
    <>
      <article className={container}>
        {hasPhone && <VerifiedBadge is_verified={is_phone_verified} />}

        <span className={value}>{phone}</span>

        <Button
          styleVariants={{ kind: 'neutral', size: 'small', type: 'outlined' }}
          onClick={togglePhoneModal}
        >
          {!hasPhone && <Icon namespace="Add" />}
          {hasPhone ? 'Change' : 'Add Phone Number'}
        </Button>
      </article>

      <BaseModal {...phoneModal}>
        <BaseModalContent className={content}>
          {isConfirmState && (
            <>
              <button
                type="button"
                onClick={reset}
                className={backButton}
                disabled={confirmPhoneReq.loading}
              >
                <Icon namespace="Backward" /> Back
              </button>

              <OTPBlock {...otpBlock} />
            </>
          )}

          {!isConfirmState && (
            <>
              <header className={header}>
                <p className={title}>
                  {hasPhone ? 'Enter New Phone Number' : 'Add Phone Number'}
                </p>
                <p className={description}>
                  {hasPhone
                    ? 'Enter new phone number to continue the changes.'
                    : 'Enter phone number to continue the process.'}
                </p>
              </header>

              <ChangePhoneRequestForm
                current_phone_code={phone_code?.replaceAll('+', '')}
                current_phone_no={phone_no}
                loading={changePhoneReq.loading}
                onCancel={closePhoneModal}
                onContinue={onContinue}
              />
            </>
          )}
        </BaseModalContent>
      </BaseModal>

      <MFABlock {...mfaBlock} />
    </>
  )
}

export default ChangePhoneBlock
