import { FC, useRef } from 'react'
import { useFocusWithin } from 'ahooks'

import { TagsNoRef } from '@genie-fintech/ui/types'
import { Icon } from '@genie-fintech/ui/icons'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

import { AuthFactor } from '$api/types'

import {
  button,
  container,
  title,
  description as descriptionStyle,
  content,
  info,
  actionIcon
} from './styles.css'

type ContainerProps = Pick<TagsNoRef<'div'>, 'role'>

export type AuthFactorCardProps = {
  data: AuthFactor
  onClick?: (data: AuthFactorCardProps['data']) => void
  disabled?: boolean
} & ContainerProps

export const AuthFactorCard: FC<AuthFactorCardProps> = ({
  data,
  onClick,
  disabled,
  ...containerProps
}) => {
  const { name, description, type } = data

  const containerRef = useRef(null)
  const isFocused = useFocusWithin(containerRef)

  return (
    <div
      data-disabled={trueOrUndefined(disabled)}
      data-focus={trueOrUndefined(isFocused)}
      className={container}
      ref={containerRef}
      {...containerProps}
    >
      <div className={content}>
        <div className={info}>
          <h5 className={title}>{name}</h5>
          <p className={descriptionStyle}>{description}</p>
        </div>

        <Icon namespace="Right" className={actionIcon} />
      </div>

      <button
        type="button"
        name={type}
        title={`Choose ${name}`}
        onClick={() => onClick?.(data)}
        disabled={disabled}
        className={button}
      />
    </div>
  )
}
