import { z } from 'zod'

import { requiredTrimmedString } from '$hook-forms/general-validators'

export const schema = z.object({
  name: requiredTrimmedString,
  address: z.string(),
  gender: z.string(),
  date_of_birth: z.string()
})

export type TFields = z.infer<typeof schema>

export const defaultValues: TFields = {
  name: '',
  address: '',
  gender: '',
  date_of_birth: ''
}
