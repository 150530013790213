import { FC, ReactNode } from 'react'

import { Button, ButtonProps } from '@genie-fintech/ui/components'

import ModalWithHeader from '$elements/ModalWithHeader'

import { useConfirmModal } from '$contexts/Modal'

import { actions, content, mainContent, messageContent } from './styles.css'

type ActionButtonProps = Omit<ButtonProps, 'onClick'>

const defaultMessage = <p>This action is irreversible.</p>

export type ConfirmModalProps = ReturnType<typeof useConfirmModal> & {
  message?: ReactNode
  cancelButtonProps?: ActionButtonProps
  confirmButtonProps?: ActionButtonProps
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  message = defaultMessage,
  confirmButtonProps,
  cancelButtonProps,
  ...props
}) => (
  <ModalWithHeader
    modalProps={props}
    className={content}
    mainClassName={mainContent}
  >
    {message && <div className={messageContent} children={message} />}

    <div className={actions}>
      <Button
        {...cancelButtonProps}
        styleVariants={{
          type: 'outlined',
          kind: 'neutral',
          ...cancelButtonProps?.styleVariants
        }}
        onClick={props.modalControls.close}
      >
        Cancel
      </Button>

      <Button
        {...confirmButtonProps}
        styleVariants={{
          kind: 'danger',
          ...confirmButtonProps?.styleVariants
        }}
        onClick={props.confirm}
      >
        Confirm
      </Button>
    </div>
  </ModalWithHeader>
)

export default ConfirmModal
