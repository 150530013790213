import { FC } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'
import BaseField, {
  BaseTextProps as BaseFieldProps
} from '@genie-fintech/ui/components/fields/BaseText'
import { Icon } from '@genie-fintech/ui/icons'

import { CallingCodes } from '$constants/country-data'
import { hideInputAppearance } from '$styles/atoms.css'

import { select, selectContainer, selectIcon } from './styles.css'

type CallingCodeProps = TagsNoRef<'select'>
type InheritedProps = Omit<BaseFieldProps, 'affix' | 'type' | 'min'>

export type PhoneProps = InheritedProps & {
  callingCodes?: CallingCodes
  callingCodeProps?: CallingCodeProps
}

export const Phone: FC<PhoneProps> = ({
  callingCodes = [],
  callingCodeProps,
  ...fieldProps
}) => {
  const baseFieldProps: BaseFieldProps = {
    ...fieldProps,
    inputProps: {
      type: 'number',
      min: '0',
      ...propsWithClassNames(fieldProps.inputProps, hideInputAppearance)
    }
  }

  return (
    <BaseField
      affix={{
        pre: (
          <label
            aria-label="Calling Code"
            onClick={e => e.stopPropagation()}
            className={selectContainer}
          >
            <select {...propsWithClassNames(callingCodeProps, select)}>
              {callingCodes.map(({ id, code, name }) => (
                <option key={id} title={name} value={code}>
                  +{code}
                </option>
              ))}
            </select>
            <Icon namespace="UpDown" className={selectIcon} />
          </label>
        )
      }}
      {...baseFieldProps}
    />
  )
}

export default Phone
