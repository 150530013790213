import { FC, PropsWithChildren, ReactNode } from 'react'

import { Icon } from '@genie-fintech/ui/icons'

import { useModalContext } from '$contexts/Modal'
import { BaseModalContent, BaseModalContentProps } from '$elements/BaseModal'

import { h2, closeButton, header } from './styles.css'

export type ContentProps = BaseModalContentProps & {
  /**
   * Renders modal's title as fallback. Use explicit `null` to skip rendering default title.
   */
  title?: ReactNode

  mainClassName?: string
}

export const Content: FC<PropsWithChildren<ContentProps>> = ({
  title: headerTitle,
  mainClassName,
  children,
  ...baseModalContentProps
}) => {
  const {
    modalOptions,
    modalControls: { close }
  } = useModalContext()

  const { title: modalTitle } = { ...modalOptions }

  const title = (() => {
    if (headerTitle === null) return

    return headerTitle || modalTitle
  })()

  return (
    <BaseModalContent {...baseModalContentProps}>
      <header className={header}>
        {title && <h2 className={h2}>{title}</h2>}
        <button onClick={close} className={closeButton} type="button">
          <Icon namespace="Cross" />
        </button>
      </header>

      <main className={mainClassName} children={children} />
    </BaseModalContent>
  )
}

export default Content
