import { FC } from 'react'

import AuthLogo from '$icons/AuthLogo'
import BgCover from '$elements/BgCover'

import { figure, logo } from './styles.css'

export const AuthLogoBanner: FC = () => (
  <figure className={figure}>
    <AuthLogo className={logo} />
    <BgCover />
  </figure>
)

export default AuthLogoBanner
