import { FC } from 'react'
import { SVGProps } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'

type SvgProps = TagsNoRef<'svg'>

export type VerifiedMarkProps = SVGProps<SVGSVGElement> & SvgProps

const VerifiedMark: FC<VerifiedMarkProps> = props => {
  return (
    <svg width="28" height="26" viewBox="0 0 28 26" fill="none" {...props}>
      <path
        d="M27.5 13C27.5 14.3652 25.6376 15.4188 25.2376 16.6515C24.8228 17.9284 25.6955 19.8732 24.9221 20.9361C24.141 22.0096 22.0165 21.7767 20.9429 22.5577C19.8801 23.3312 19.4495 25.4265 18.1726 25.8425C16.9408 26.2427 15.3652 24.8125 14 24.8125C12.6348 24.8125 11.0593 26.2427 9.82738 25.8425C8.5505 25.4279 8.11962 23.3312 7.05706 22.5577C5.98353 21.7764 3.85897 22.0093 3.07766 20.9361C2.30422 19.8732 3.17694 17.9284 2.76238 16.6515C2.36244 15.4188 0.5 14.3652 0.5 13C0.5 11.6348 2.36244 10.5813 2.76238 9.34856C3.17722 8.07168 2.3045 6.12684 3.07794 5.06399C3.85897 3.99046 5.98353 4.22334 7.05706 3.44231C8.11991 2.66887 8.5505 0.573556 9.82738 0.157587C11.0593 -0.242631 12.6348 1.18752 14 1.18752C15.3652 1.18752 16.9408 -0.242631 18.1726 0.157587C19.4495 0.57215 19.8804 2.66887 20.9429 3.44231C22.0165 4.22362 24.141 3.99074 24.9223 5.06399C25.6958 6.12684 24.8231 8.07168 25.2376 9.34856C25.6376 10.5813 27.5 11.6348 27.5 13Z"
        fill="#FFCA3A"
      />
      <path
        d="M14 21.8906C18.9101 21.8906 22.8906 17.9101 22.8906 13C22.8906 8.08983 18.9101 4.10938 14 4.10938C9.08983 4.10938 5.10938 8.08983 5.10938 13C5.10938 17.9101 9.08983 21.8906 14 21.8906Z"
        fill="#F99410"
      />
      <path
        d="M13.2272 16.0095C13.0979 16.0096 12.9698 15.9841 12.8503 15.9347C12.7308 15.8852 12.6223 15.8127 12.5308 15.7212L10.0277 13.2181C9.84311 13.0335 9.73945 12.783 9.7395 12.522C9.73955 12.2609 9.84332 12.0105 10.028 11.8259C10.2126 11.6413 10.463 11.5377 10.7241 11.5377C10.9852 11.5378 11.2356 11.6415 11.4202 11.8262L13.2269 13.633L16.5794 10.2802C16.764 10.0955 17.0144 9.99175 17.2754 9.9917C17.5365 9.99165 17.7869 10.0953 17.9716 10.2799C18.1563 10.4645 18.26 10.7148 18.2601 10.9759C18.2601 11.237 18.1565 11.4874 17.9719 11.6721L13.9219 15.7221C13.7375 15.906 13.4877 16.0094 13.2272 16.0095Z"
        fill="#FFFAE3"
      />
    </svg>
  )
}

export default VerifiedMark
