import { z } from 'zod'

import { PasswordRules } from '$store/settings'
import { CF_TURNSTILE_ENABLED } from '$constants'

export const requiredString = z.string().min(1, { message: 'Required' })

export const captchaString = (() => {
  const str = z.string({ message: 'Must be a valid captcha token' })

  if (!CF_TURNSTILE_ENABLED) return str.optional()

  return str.min(1, { message: 'Captcha Required' })
})()

export const requiredTrimmedString = z
  .string()
  .trim()
  .min(1, { message: 'Required' })

export const requiredTrimmedEmail = requiredTrimmedString.email()

export const generateRequiredPasswordString = (rules: PasswordRules) => {
  const fieldSchema = requiredString.superRefine((value, ctx) => {
    rules.forEach(({ description, regex }) => {
      if (!regex.test(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: description,
          params: { regex }
        })
      }
    })
  })

  const getFailedRules = (password: z.infer<typeof fieldSchema>) => {
    const { success, error } = fieldSchema.safeParse(password)

    if (success) return []

    const { issues } = error

    const errors = issues.map(issue => {
      const isCustom = issue.code === 'custom'

      if (!isCustom) return false
      if (!issue.params) return false

      return `${issue.params.regex}`
    })

    return errors.filter(Boolean) as string[]
  }

  return { fieldSchema, getFailedRules }
}
