import { FC, useImperativeHandle, useRef } from 'react'
import { useBoolean } from 'ahooks'
import { Simplify } from 'type-fest'

import { Icon } from '@genie-fintech/ui/icons'
import { TagsNoRef, ToReferences } from '@genie-fintech/ui/types'
import {
  CommonFieldStateProps,
  CommonTextFieldProps,
  CommonWithAffixProps,
  CommonWithFieldContainerProps
} from '@genie-fintech/ui/components/fields/common/types'
import {
  propsWithClassNames,
  toClassNames,
  trueOrUndefined
} from '@genie-fintech/ui/functions'
import {
  affixControl,
  AffixControl,
  element,
  elementControl
} from '@genie-fintech/ui/components/fields/common/style.css'
import { FieldContainer } from '@genie-fintech/ui/components/fields'

import { container, select } from './styles.css'
import { Option } from './types'

type VNodes = {
  elementControlRef: HTMLDivElement
  selectRef: HTMLSelectElement
}

type References = Partial<ToReferences<VNodes>>

type EleCtrlTagProps = TagsNoRef<'div'>
type SelectTagProps = TagsNoRef<'select'>

type ElementControlProps = CommonWithAffixProps &
  CommonTextFieldProps &
  References & {
    elementControlProps?: EleCtrlTagProps
    selectProps?: SelectTagProps
  }

export type SelectFieldProps = Simplify<
  CommonWithFieldContainerProps & ElementControlProps
> & { options: Option[] }

export const SelectField: FC<SelectFieldProps> = ({
  selectProps,
  elementControlProps,
  placeholder,
  affix,
  selectRef,
  elementControlRef,
  options,
  ...restFieldContainerProps
}) => {
  const selectNode = useRef<VNodes['selectRef']>(null)

  const { disabled, error, success } = restFieldContainerProps
  const { post: suffix = <Icon namespace="Down" width={16} />, pre: prefix } = {
    ...affix
  }
  const {
    onFocus: onFocusNative,
    onBlur: onBlurNative,
    className,
    onChange,
    ...restSelectTagProps
  } = { ...selectProps }
  const { onClick: controlNativeOnclick, ...restElementControlProps } = {
    ...elementControlProps
  }

  const [isFocus, { setFalse, setTrue }] = useBoolean()

  const fieldContainerProps: CommonWithFieldContainerProps = {
    ...restFieldContainerProps,
    containerProps: propsWithClassNames(
      restFieldContainerProps.containerProps,
      container
    )
  }

  const stateProps: CommonFieldStateProps = {
    'data-success': trueOrUndefined(success),
    'data-error': trueOrUndefined(error),
    'data-disabled': trueOrUndefined(disabled),
    'data-focus': trueOrUndefined(isFocus)
  }

  const position: AffixControl['position'] = (() => {
    const hasPrefix = !!prefix
    const hasSuffix = !!suffix

    if (hasPrefix && hasSuffix) {
      return 'both'
    } else if (hasPrefix) {
      return 'pre'
    } else if (hasSuffix) {
      return 'post'
    }
  })()

  const selectClassName = toClassNames(select, element, className)
  const controlClassName = toClassNames(
    elementControl,
    affixControl({ position })
  )

  // Auto-focus input upon wrapper click
  const controlOnClick: EleCtrlTagProps['onClick'] = (...args) => {
    selectNode.current?.focus()
    controlNativeOnclick?.(...args)
  }

  const onFocus: SelectTagProps['onFocus'] = (...args) => {
    setTrue()
    onFocusNative?.(...args)
  }

  const onBlur: SelectTagProps['onBlur'] = (...args) => {
    setFalse()
    onBlurNative?.(...args)
  }

  useImperativeHandle(selectRef, () => selectNode.current as never)

  return (
    <FieldContainer {...fieldContainerProps}>
      {({ elementProps: { id } }) => (
        <div
          ref={elementControlRef}
          className={controlClassName}
          onClick={controlOnClick}
          {...stateProps}
          {...restElementControlProps}
        >
          {prefix}
          <select
            ref={selectNode}
            className={selectClassName}
            {...stateProps}
            {...{
              id,
              placeholder,
              onFocus,
              onBlur,
              disabled,
              onChange: e => {
                e.stopPropagation()
                onChange?.(e)
              }
            }}
            {...restSelectTagProps}
          >
            {options.map((opt, idx) =>
              typeof opt === 'string' ? (
                <option key={idx} value={opt} title={opt}>
                  {opt}
                </option>
              ) : (
                <option key={idx} value={opt.value} title={opt.label}>
                  {opt.label}
                </option>
              )
            )}
          </select>
          {suffix}
        </div>
      )}
    </FieldContainer>
  )
}

export default SelectField
