import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import useRequiredPasswordString from '$hook-forms/hooks/useRequiredPasswordString'

import { baseSchema, refineSchema, defaultValues } from './schema'
import { UpdatePasswordFormProps } from '.'

export const useUpdatePasswordForm = (props: UpdatePasswordFormProps) => {
  const { disabled } = props
  const { fieldSchema, getFailedRules } = useRequiredPasswordString()

  const dynamicSchema = useMemo(
    () => refineSchema(baseSchema.extend({ password: fieldSchema })),
    [fieldSchema]
  )
  const resolver = zodResolver(dynamicSchema)

  const { control, reset, handleSubmit, formState, getValues } = useForm({
    resolver,
    defaultValues,
    mode: 'all'
  })

  const isInvalid = disabled || !!Object.keys(formState.errors).length

  const passwordValue = getValues('password')
  const hasPassword = !!passwordValue
  const invalidRules = useMemo(
    () => getFailedRules(passwordValue),
    [passwordValue, getFailedRules]
  )

  return {
    ...props,
    control,
    reset,
    handleSubmit,
    isInvalid,
    invalidRules,
    hasPassword
  }
}

export default useUpdatePasswordForm
