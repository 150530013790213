import SelectHookField from '$hook-fields/Select'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import { FC, useImperativeHandle, useRef } from 'react'
import { flexColumn } from './styles.css'
import { UseUpdateProfileBasicInfoForm } from './useUpdateProfileBasicInfoForm'
import { GENDER_OPTIONS } from '$constants'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'
import { content } from '$blocks/ProfileBasicInfo/styles.css'

type HookProps = TagsNoRef<'form'> & UseUpdateProfileBasicInfoForm

export const UpdateProfileBasicInfoForm: FC<HookProps> = ({
  onSuccess,
  disabled,

  reset,
  handleSubmit,
  control,
  isInvalid,

  submitInputRef: _submitInputRef,

  ...props
}) => {
  const submitInputRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(_submitInputRef, () => submitInputRef.current as never)

  return (
    <form
      onSubmit={handleSubmit((...handler) => onSuccess(reset, ...handler))}
      {...propsWithClassNames(props, content)}
    >
      <BaseText
        name="name"
        control={control}
        label="User Name"
        containerProps={{ className: flexColumn }}
        disabled={disabled}
        required
      />

      <BaseText
        name="date_of_birth"
        control={control}
        label="Date of Birth"
        containerProps={{ className: flexColumn }}
        disabled={disabled}
        inputProps={{ type: 'date' }}
      />

      <SelectHookField
        name="gender"
        control={control}
        label="Gender"
        containerProps={{ className: flexColumn }}
        disabled={disabled}
        options={GENDER_OPTIONS}
      />

      <Textarea
        name="address"
        control={control}
        label="Address"
        containerProps={{ className: flexColumn }}
        disabled={disabled}
      />

      <input ref={submitInputRef} type="submit" disabled={isInvalid} hidden />
    </form>
  )
}

export default UpdateProfileBasicInfoForm
