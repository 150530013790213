import { FC } from 'react'

import { useRouteMeta } from '$actions/useRouteMeta'
import { useRouter } from '$actions/useRouter'

import AccountSelectionGuard from '$guards/AccountSelectionGuard'

import AuthLayout from '$layouts/AuthLayout'
import CenteredLayout from '$layouts/CenteredLayout'
import AuthenticatedLayout from '$layouts/AuthenticatedLayout'

import ProfileWaiter from '$blocks/ProfileWaiter'

import Views from '$views'
import Root from '$views/Root'
import Login from '$views/Login'
import ReplacePassword from '$views/ReplacePassword'
import ForgotPassword from '$views/ForgotPassword'
import ResetPassword from '$views/ResetPassword'
import SmsOtpVerify from '$views/SmsOtpVerify'
import Accounts from '$views/Accounts'
import App from '$views/App'
import ProfileDetail from '$views/ProfileDetail'
import MFASetting from '$views/MFASetting'
import PasswordSetting from '$views/PasswordSetting'
import Applications from '$views/Applications'
import LoginHistory from '$views/LoginHistory'
import EmailVerification from '$views/EmailVerification'

export const Routing: FC = () => {
  useRouteMeta()

  const { is404, on, onOneOf } = useRouter()

  const isAuthLayout = onOneOf([
    'auth',
    'authAccounts',
    'authApp',
    'authLogin',
    'authReplacePassword',
    'authForgotPassword',
    'authResetPassword',
    'authSmsOtpVerify',
    'authEmailVerification'
  ])
  const isUsingAuthSelectionGuard = onOneOf(['authApp', 'authReplacePassword'])
  const isAuthenticatedLayout = onOneOf([
    'applications',
    'profileDetail',
    'securityMfa',
    'securityPassword',
    'securityLoginHistory'
  ])
  const isCenteredLayout = is404 || onOneOf(['root'])

  return (
    <Views>
      {isAuthLayout && (
        <AuthLayout>
          {on('authLogin') && <Login />}

          {on('authForgotPassword') && <ForgotPassword />}

          {on('authResetPassword') && <ResetPassword />}

          {on('authSmsOtpVerify') && <SmsOtpVerify />}

          {on('authEmailVerification') && <EmailVerification />}

          {isUsingAuthSelectionGuard && (
            <AccountSelectionGuard>
              {on('authReplacePassword') && (
                <ProfileWaiter>
                  <ReplacePassword />
                </ProfileWaiter>
              )}

              {on('authApp') && <App />}
            </AccountSelectionGuard>
          )}

          {on('authAccounts') && <Accounts />}
        </AuthLayout>
      )}

      {isAuthenticatedLayout && (
        <AccountSelectionGuard>
          <AuthenticatedLayout>
            {on('applications') && <Applications />}

            {on('profileDetail') && <ProfileDetail />}

            {on('securityPassword') && <PasswordSetting />}

            {on('securityMfa') && <MFASetting />}

            {on('securityLoginHistory') && <LoginHistory />}
          </AuthenticatedLayout>
        </AccountSelectionGuard>
      )}

      {isCenteredLayout && (
        <CenteredLayout>
          {on('root') && <Root />}

          {is404 && '404 / Page not found'}
        </CenteredLayout>
      )}
    </Views>
  )
}

export default Routing
