import { useCallback, useEffect, useRef, useState } from 'react'

import { BaseTextProps } from '@genie-fintech/ui/components/fields'

import { DEFAULT_OTP_CODE_LENGTH } from '$constants'
import { isNumeric, wait } from '$app/utilities'

type useOTPInputOptions = {
  className?: string
  length?: number
  disabled?: boolean
  onFilled: (passwords: string, reset: VoidFunction) => void
  autoFocus?: boolean
  defaultValue?: string
}

export const useOTPInput = (options: useOTPInputOptions) => {
  const {
    defaultValue = '',
    length = DEFAULT_OTP_CODE_LENGTH,
    disabled,
    onFilled,
    autoFocus
  } = options

  const emptyPasswords = Array.from({ length }, () => defaultValue)

  const containerRef = useRef<HTMLElement>(null)
  const [passwords, setPasswords] = useState(emptyPasswords)

  const safeValues = passwords.filter(isNumeric)
  const isFilled = length === safeValues.length
  const formattedValues = safeValues.join('')

  const passwordFieldProps: BaseTextProps[] = passwords.map((value, index) => ({
    placeholder: 'x',
    disabled,
    inputProps: {
      value,
      inputMode: 'numeric',
      step: 1,
      min: 0,
      max: 9,
      maxLength: 1,
      'data-index': index,
      autoComplete: 'one-time-code',
      autoFocus: autoFocus && index == 0,

      onChange: e => {
        const value = e.target.value || ''

        // When tapping the OTP autocomplete, especially on mobile keyboard,
        // onPaste event doesn't get triggered.
        // this case handles it by checking the length of value
        // if the autocomplete value is valid, it should at least have the same length of input counts
        if (value.length === length) {
          fillPasswords(value)

          return
        }

        // pick only last character
        const [password = defaultValue] = value
          .split('')
          .reverse()
          .filter(isNumeric)

        setPassword(password, index)

        if (password !== defaultValue) {
          focusNext(index)
        }
      },
      onKeyUp: e => {
        const keyName = e.key.toLowerCase()

        if (keyName !== 'backspace') return

        setPassword(defaultValue, index)
        focusPrevious(index)
      },
      onPaste: e => {
        e.preventDefault()

        fillPasswords(e.clipboardData.getData('text'))
      }
    }
  }))

  const setPassword = (value: string, index: number) => {
    setPasswords(old =>
      old.map((originalValue, currentKey) => {
        if (currentKey === index) return value

        return originalValue
      })
    )
  }

  const fillPasswords = (value: string) => {
    if (disabled) return
    value.split('').filter(isNumeric).forEach(setPassword)
  }

  const resetPasswords = () => setPasswords(emptyPasswords)

  const focus = (index: number) => {
    const root = containerRef.current

    if (!root) return

    const input = root.querySelector<HTMLInputElement>(
      `input[data-index='${index}']`
    )

    if (!input) return

    input.focus()
  }
  const focusNext = (index: number) => {
    focus(index + 1)
  }
  const focusPrevious = (index: number) => {
    focus(index - 1)
  }
  const blur = useCallback(() => {
    const root = containerRef.current

    if (!root) return

    passwords.forEach((_, index) => {
      root
        .querySelector<HTMLInputElement>(`input[data-index='${index}']`)
        ?.blur()
    })
  }, [passwords])

  useEffect(() => {
    if (!isFilled) return

    onFilled(formattedValues, resetPasswords)
    blur()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilled, formattedValues, blur])

  // WebOTP
  useEffect(() => {
    // On non-localhost http, credentials could be undefined
    if (!navigator.credentials || disabled) return

    const controller = new AbortController()

    // Wait OTP
    navigator.credentials
      .get({
        signal: controller.signal,

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        otp: {
          transport: ['sms']
        }
      })
      .then(otp => {
        if (!otp || controller.signal.aborted) return

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        fillPasswords(otp.code)
      })
      // avoid throwing error
      .catch(() => {})

    return () => {
      const run = async () => {
        // TODO: Chromium bug fix: To prevent crashes.
        // Reason:
        // Chromium can't abort the event immediately. In this case, crashes the tab.
        // This happens mainly in localhost.
        // Certain conditions trigger this: Oh yah its our boy React again?
        // - Partly because of how React.strictmode behave.
        // - This hook was originally extracted from OTPInput element component:
        //   - It didn't happen when it was a component,
        //   - That means custom react hook's lifecycle works a little bit different than component lifecycle.
        //   - Different in terms of speed: Hook's lifecycle runs faster than component.
        //
        // Noting can beat a good ol' delay.
        await wait(100)

        controller.abort('Stopped listening otp')
      }

      run()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  return {
    ...options,
    containerRef,
    resetPasswords,
    passwordFieldProps
  }
}

export default useOTPInput
