import useSignal from '$actions/useSignal'
import PageContents from '$layouts/AuthenticatedLayout/PageContents'
import { profile } from '$store/profile'
import Details from '$blocks/ProfileBasicInfo/Details'
import { Button } from '@genie-fintech/ui/components'
import { format } from 'date-fns'
import { capitalize } from 'lodash-es'
import { FC } from 'react'
import { content, main, nav } from './styles.css'
import { useBoolean, useRequest } from 'ahooks'
import { Icon } from '@genie-fintech/ui/icons'
import api from '$model/api'
import { ToastMessage } from '$app/types'
import { errorMessageResolver } from '$services/api/common'
import toast from 'react-hot-toast'
import { refreshStates } from '$store/profiles'
import useUpdateProfileBasicInfoForm from '$hook-forms/UpdateProfileBasicInfoForm/useUpdateProfileBasicInfoForm'
import UpdateProfileBasicInfoForm from '$hook-forms/UpdateProfileBasicInfoForm'
import { GENDER_OPTIONS } from '$constants'
import CenterLoader from '$elements/CenterLoader'

const toastMsg: ToastMessage = {
  loading: 'Updating..',
  error: errorMessageResolver,
  success: 'Profile update successful.'
}

const ProfileBasicInfo: FC = () => {
  const [isEditMode, { setTrue: setEditModeTrue, setFalse: setEditModeFalse }] =
    useBoolean()

  const profileStore = useSignal(profile)
  const { loading: fetchingProfile, data } = { ...profileStore }

  const {
    auth: { updateProfile }
  } = useSignal(api)

  const { runAsync: updateProfileAsync, loading: updatingProfile } = useRequest(
    updateProfile,
    {
      manual: true,
      onSuccess: () => {
        setEditModeFalse()
      }
    }
  )

  const { name, address, gender, date_of_birth } = { ...data }

  const formProps = useUpdateProfileBasicInfoForm({
    onSuccess: (_, data) => {
      toast.promise(updateProfileAsync(data), toastMsg).then(() => {
        setEditModeFalse()
        refreshStates()
      })
    },
    disabled: updatingProfile
  })

  const onEdit = () => {
    formProps.reset({
      name,
      address: address ?? '',
      gender: gender ?? GENDER_OPTIONS[0].value,
      date_of_birth: date_of_birth ?? ''
    })

    setEditModeTrue()
  }

  const onCancel = () => {
    formProps.reset()
    setEditModeFalse()
  }

  const onSave = () => {
    formProps.submitInputRef.current?.click()
  }

  const isDetailView = !isEditMode && !fetchingProfile
  const isEditView = isEditMode && !fetchingProfile

  return (
    <PageContents
      title="Information"
      description="Some of the user informations can be editable."
      navProps={{ className: nav }}
      mainClassName={main}
      nav={
        <>
          {isEditMode && (
            <>
              <Button
                styleVariants={{
                  kind: 'neutral',
                  type: 'outlined',
                  size: 'small'
                }}
                onClick={onCancel}
                disabled={updatingProfile}
              >
                Cancel
              </Button>

              <Button
                styleVariants={{ size: 'small' }}
                onClick={onSave}
                disabled={formProps.isInvalid}
              >
                Save
              </Button>
            </>
          )}

          {!isEditMode && (
            <Button
              styleVariants={{
                kind: 'neutral',
                type: 'outlined',
                size: 'small'
              }}
              onClick={onEdit}
            >
              <Icon namespace="EditPencil" width={16} />
              Edit
            </Button>
          )}
        </>
      }
    >
      {fetchingProfile && <CenterLoader />}

      {isDetailView && (
        <article className={content}>
          <Details title="User Name" data-testid="profile-info-name">
            {name}
          </Details>

          <Details title="Date of Birth" data-testid="profile-info-dob">
            {date_of_birth ? format(date_of_birth, 'dd MMM yyyy') : '-'}
          </Details>

          <Details title="Gender" data-testid="profile-info-gender">
            {gender ? capitalize(gender) : '-'}
          </Details>

          <Details title="Address" data-testid="profile-info-address">
            {address ?? '-'}
          </Details>
        </article>
      )}

      {isEditView && <UpdateProfileBasicInfoForm {...formProps} />}
    </PageContents>
  )
}

export default ProfileBasicInfo
