import { computed } from '@preact/signals-core'

import { profiles, refreshState as profileRefreshState } from './profiles'
import { current } from './session'

export const profile = computed(() =>
  profiles.value.data.find(({ id }) => id === current.value)
)

export const refreshState = async () => {
  const { id } = { ...profile.value }

  if (!id) return

  await profileRefreshState(id)
}
