import useModal, { UseModalOptions } from './useModal'

export type UseConfirmModalOptions = UseModalOptions & {
  onConfirm: (close: VoidFunction) => void
}

export const useConfirmModal = ({
  onConfirm,
  ...modalOptions
}: UseConfirmModalOptions) => {
  const modal = useModal(modalOptions)

  const confirm = () => {
    onConfirm(modal.modalControls.close)
  }

  return {
    ...modal,
    confirm
  }
}

export default useConfirmModal
