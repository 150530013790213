import { FC } from 'react'

import { Password } from '@genie-fintech/ui/components/hook-fields'

import { TypeSubmitForm } from '$hook-forms/types'

import PasswordGuide from '$elements/PasswordGuide'

import { submit } from '$forms/common/style.css'

import { TFields } from './schema'
import { useUpdatePasswordForm } from './useUpdatePasswordForm'

import { container } from './styles.css'

export type UpdatePasswordFormFields = TFields

export type UpdatePasswordFormProps = TypeSubmitForm<TFields>

type HookProps = ReturnType<typeof useUpdatePasswordForm>

export const UpdatePasswordForm: FC<HookProps> = ({
  onSuccess,
  disabled,

  control,
  handleSubmit,
  invalidRules,
  isInvalid,
  reset,
  hasPassword
}) => (
  <form
    className={container}
    onSubmit={handleSubmit((...handler) => onSuccess(reset, ...handler))}
    noValidate
    autoComplete="off"
  >
    <Password
      control={control}
      name="password"
      label="New Password"
      disabled={disabled}
      required
    />
    <Password
      control={control}
      name="confirm_password"
      label="Confirm password"
      disabled={disabled}
    />

    <button type="submit" className={submit} disabled={isInvalid}>
      Update
    </button>

    <PasswordGuide invalidRules={invalidRules} disabled={!hasPassword} />
  </form>
)

export default UpdatePasswordForm
