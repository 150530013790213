import { useEffect, useRef, useState } from 'react'

import { CF_TURNSTILE_KEY } from '$constants'

type Options = {
  disabled?: boolean
  autoReset?: boolean
  onReset?: VoidFunction
  onError?: VoidFunction
  onSuccess: Required<Turnstile.RenderParameters>['callback']
}

const sitekey = CF_TURNSTILE_KEY

export const useCFTurnstile = <TargetRef extends HTMLElement>({
  disabled,
  autoReset = true,
  onSuccess,
  onReset,
  onError
}: Options) => {
  const [token, setToken] = useState<string>()
  const [widgetId, setWidgetId] = useState<string>()
  const targetRef = useRef<TargetRef>(null)

  const onErrorCallback = () => {
    onError?.()

    if (!autoReset) return

    reset()
  }

  const reset = () => {
    if (!widgetId || !targetRef.current) return

    turnstile.reset(widgetId)
    onReset?.()
  }

  useEffect(() => {
    if (disabled) return

    const target = targetRef.current

    if (!target) return

    const id = turnstile.render(target, {
      sitekey,
      size: 'flexible',
      callback: token => {
        setToken(token)
        onSuccess(token)
      },
      ['timeout-callback']: onErrorCallback,
      ['expired-callback']: onErrorCallback,
      ['error-callback']: onErrorCallback
    })

    if (!id) return

    setWidgetId(id)

    return () => {
      setWidgetId(undefined)
      turnstile.remove(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  return {
    targetRef,
    widgetId,
    token,
    reset
  }
}

export default useCFTurnstile
