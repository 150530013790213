import { useBoolean, useRequest } from 'ahooks'
import toast from 'react-hot-toast'
import { renderSVG } from 'uqr'

import { useModal } from '$contexts/Modal'
import useSignal from '$actions/useSignal'
import useMFABlock from '$actions/useMFABlock'
import use2FAHelper from '$actions/use2FAHelper'

import { asyncSuccessResolver, clipboardCopyText } from '$app/utilities'
import { ToastMessage } from '$app/types'

import api from '$model/api'
import { errorMessageResolver, successResolver } from '$services/api/common'
import { TOTPRegisterFormProps } from '$hook-forms/TOTPRegisterForm'

export type UseSetupOptions = {
  onUpdated?: () => void
}

export type UseSetupReturn = ReturnType<typeof useSetup>

const setupReqToastMsg: ToastMessage = {
  loading: 'Requesting..',
  error: errorMessageResolver,
  success: 'Secret requested.'
}

export const useSetup = ({ onUpdated }: UseSetupOptions) => {
  const modal = useModal({
    disabledOverlayClick: true,
    onClose: () => {
      reset()
      mfaBlock.exit()
    },
    title: 'Secure Your Account'
  })

  const [showSecret, { toggle: toggleSecret }] = useBoolean()

  const { mfaAuth: apiInstance } = useSignal(api)
  const { mfaRegisterTOTPRequest, mfaRegisterTOTPSetup } = apiInstance

  const setupReq = useRequest(
    asyncSuccessResolver(mfaRegisterTOTPRequest, successResolver),
    {
      manual: true,
      onError: err => {
        if (twoFAHelper.is2FAError(err)) {
          mfaBlock.modalControls.open()
        }
      },
      onSuccess: () => {
        mfaBlock.exit()
      }
    }
  )

  const verifyReq = useRequest(mfaRegisterTOTPSetup, {
    manual: true,
    onSuccess: () => {
      modal.modalControls.close()
      onUpdated?.()
    }
  })

  const requests = [setupReq, verifyReq]
  const isAnyLoading = requests.some(({ loading }) => loading)
  const isLoading = setupReq.loading

  const twoFAHelper = use2FAHelper({ getMfaBlock: () => mfaBlock })
  const available_factors = twoFAHelper.getFactorsByError(setupReq.error)

  const mfaBlock = useMFABlock({
    apiInstance,
    data: { available_factors },
    onCancelled: modal.modalControls.close,
    onVerified: mfa_token => {
      const { params, runAsync } = setupReq

      // Make sure there was a previous call
      if (!params.length) return

      const [data] = params

      // Auto retry the request with token
      toast
        .promise(runAsync({ ...data, mfa_token }), setupReqToastMsg)
        .catch(twoFAHelper.reChallengeOrExitOnError)
    }
  })

  const { mfa_token } = mfaBlock
  const { secret } = { ...setupReq.data?.data }

  const qrSvg = (() => {
    const { url } = { ...setupReq.data?.data }

    if (!url) return

    return renderSVG(url, {
      border: 0,
      blackColor: 'currentcolor',
      whiteColor: 'transparent'
    })
  })()

  const reset = () => {
    requests.forEach(({ cancel, mutate }) => {
      cancel()
      mutate()
    })
  }

  const copySecret = () => {
    if (!secret) return

    clipboardCopyText(secret)

    toast.success('Secret copied to clipboard')
  }

  const onSetup = () => {
    modal.modalControls.open()

    toast.promise(setupReq.runAsync({ mfa_token }), setupReqToastMsg)
  }

  const onVerifySubmit: TOTPRegisterFormProps['onSuccess'] = async (
    reset,
    data
  ) => {
    await toast.promise(verifyReq.runAsync(data), {
      loading: 'Verifying..',
      error: errorMessageResolver,
      success: 'Authenticator setup successful.'
    })

    reset()
  }

  const onSetupCancel = () => {
    modal.modalControls.close()
  }

  return {
    modal,
    onSetup,
    onSetupCancel,
    isLoading,
    isAnyLoading,
    mfaBlock,
    qrSvg,
    onVerifySubmit,
    showSecret,
    copySecret,
    toggleSecret,
    secret
  }
}

export default useSetup
