import { CommonAPI, Token } from './common'
import {
  AppDetailRequest,
  AppDetailResponse,
  ChangeEmailInitiateRequest,
  ChangeEmailVerifyRequest,
  ChangePhoneInitiateRequest,
  ChangePhoneVerifyRequest,
  CheckResponse,
  GetAPIType,
  GetCodeRequest,
  GetCodeResponse,
  ListPublicAppsResponse,
  LoginDevicesResponse,
  ProfileResponse,
  ReplacePasswordRequest,
  ReplacePasswordResponse,
  SettingsResponse,
  UpdatePasswordRequest,
  UpdateProfileRequest,
  VerifyPhoneConfirmRequest
} from './types'

import { baseURL } from './constants'

export class AuthAPI extends CommonAPI {
  constructor(token: Token) {
    super({ baseURL })

    this.filterRequestPayload()

    this.updateToken(token)
  }

  public profile = () => {
    return this.api.get<ProfileResponse>('/profile')
  }

  /**
   * Requires `2FA` guard handling.
   *
   * Error type: `With2FAGuardErrorResponse`
   */
  public updatePassword = (data: UpdatePasswordRequest) => {
    return this.api.put('/profile/passwords', data)
  }

  public settings = () => {
    return this.api.get<SettingsResponse>('/settings')
  }

  public check = () => {
    return this.api.get<CheckResponse>('/oauth/is-authenticated')
  }

  public replacePassword = (data: ReplacePasswordRequest) => {
    return this.api.post<ReplacePasswordResponse>('/replace-password', data)
  }

  public logout = () => {
    return this.api.post('/logout')
  }

  public getCode = (data: GetCodeRequest) => {
    return this.api.post<GetCodeResponse>('/oauth/authorize', data)
  }

  public listPublicApps = () => {
    return this.api.get<ListPublicAppsResponse>('/applications')
  }

  public appDetail = ({ client_id, ...data }: AppDetailRequest) => {
    return this.api.get<AppDetailResponse>(`/applications/${client_id}`, {
      data
    })
  }

  public loginDevices = () => {
    return this.api.get<LoginDevicesResponse>('/login-histories')
  }

  public putTimezone = (data: { timezone: string }) => {
    return this.api.put('/timezones', data)
  }

  public changeEmailRequest = (data: ChangeEmailInitiateRequest) => {
    return this.api.post('/profile/email/request', data)
  }

  public changeEmailConfirm = (data: ChangeEmailVerifyRequest) => {
    return this.api.post('/profile/email/confirm', data)
  }

  public changePhoneRequest = (data: ChangePhoneInitiateRequest) => {
    return this.api.post('/profile/phone/request', data)
  }

  public changePhoneConfirm = (data: ChangePhoneVerifyRequest) => {
    return this.api.post('/profile/phone/confirm', data)
  }

  public emailVerificationRequest = () => {
    return this.api.post('/profile/email/verification/request')
  }

  public phoneVerificationRequest = () => {
    return this.api.post('/profile/phone/verification/request')
  }

  public phoneVerificationConfirm = (data: VerifyPhoneConfirmRequest) => {
    return this.api.post('/profile/phone/verification/confirm', data)
  }

  public updateProfile = (data: UpdateProfileRequest) => {
    return this.api.put('/profile', data)
  }
}

export type TypeAuthAPI = GetAPIType<AuthAPI>
