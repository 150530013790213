export const SIZE = 20

export const ID = 'extra'

export const NAMESPACES = [
  'layer',
  'laptop-2',
  'shield',
  'grear',
  'clock',
  'map-pin',
  'alert-triangle',
  'email-tick',
  'mobile-phone',
  'qr-code'
] as const

export type Namespaces = (typeof NAMESPACES)[number]
